/* eslint max-len: 0 */
import React, { SVGProps } from 'react';

const ChevronRightBold = (props: SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      width="6"
      height="9"
      viewBox="0 0 6 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="icon icon--fill-chevron-right"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.28993 8.9834L0.494141 8.18761L4.17587 4.50588L0.49414 0.824157L1.28993 0.0283699L5.76744 4.50588L1.28993 8.9834Z"
        fill="#2D3548"
      />
    </svg>
  );
};

ChevronRightBold.displayName = 'ChevronRightBold';

export default ChevronRightBold;
