import { lazy } from 'react';

/**
Maps the Monarch UI Component customId to the React component path in our repo
**/
const listOfMonarchComponents = {
  cartConfigContactPage: lazy(() => import('./Config | Contact Page')),
  cartOutageContactForm: lazy(() => import('./Outage Contact Form')),
  cartRedesignAllconnectUnserv: lazy(() => import('./Redesign | Allconnect Unserv')),
};

export default listOfMonarchComponents;
