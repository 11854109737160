/* eslint max-len: 0 */
import React, { SVGProps } from 'react';

const shieldCheckmark = (props: SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="icon icon--shield-checkmark"
      {...props}
    >
      <path
        d="M22 0H2C1.46957 0 0.960859 0.210714 0.585786 0.585786C0.210714 0.960859 0 1.46957 0 2V9C0 15.59 3.19 19.5837 5.86625 21.7738C8.74875 24.1313 11.6163 24.9312 11.7413 24.965C11.9131 25.0118 12.0944 25.0118 12.2663 24.965C12.3913 24.9312 15.255 24.1313 18.1413 21.7738C20.81 19.5837 24 15.59 24 9V2C24 1.46957 23.7893 0.960859 23.4142 0.585786C23.0391 0.210714 22.5304 0 22 0ZM22 9C22 13.6337 20.2925 17.395 16.925 20.1775C15.4591 21.3846 13.7919 22.324 12 22.9525C10.2315 22.335 8.58494 21.4123 7.135 20.2262C3.7275 17.4387 2 13.6625 2 9V2H22V9ZM6.2925 12.7075C6.10486 12.5199 5.99944 12.2654 5.99944 12C5.99944 11.7346 6.10486 11.4801 6.2925 11.2925C6.48014 11.1049 6.73464 10.9994 7 10.9994C7.26536 10.9994 7.51986 11.1049 7.7075 11.2925L10 13.5863L16.2925 7.2925C16.3854 7.19959 16.4957 7.12589 16.6171 7.07561C16.7385 7.02532 16.8686 6.99944 17 6.99944C17.1314 6.99944 17.2615 7.02532 17.3829 7.07561C17.5043 7.12589 17.6146 7.19959 17.7075 7.2925C17.8004 7.38541 17.8741 7.49571 17.9244 7.6171C17.9747 7.7385 18.0006 7.86861 18.0006 8C18.0006 8.1314 17.9747 8.2615 17.9244 8.3829C17.8741 8.50429 17.8004 8.61459 17.7075 8.7075L10.7075 15.7075C10.6146 15.8005 10.5043 15.8742 10.3829 15.9246C10.2615 15.9749 10.1314 16.0008 10 16.0008C9.86858 16.0008 9.73846 15.9749 9.61706 15.9246C9.49566 15.8742 9.38537 15.8005 9.2925 15.7075L6.2925 12.7075Z"
        fill={props?.fill || '#FAFAFA'}
      />
    </svg>
  );
};

shieldCheckmark.displayName = 'shieldCheckmark';

export default shieldCheckmark;
