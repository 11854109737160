/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import clientSide from '~/helpers/clientSide';
import { getQuoteId } from '~/helpers/getQuoteId';
import { setNewRelicCustomAttribute } from '~/helpers/newrelic';
import { getOrCreateProcessId } from '../cartFlow';

const getCurrentUrl = () => clientSide && window.location && window.location.href;

export const EVENTS = {
  ElementClicked: 'redventures.orderprocessing.v1.ElementClicked',
  ElementViewed: 'redventures.orderprocessing.v1.ElementViewed',
  FieldSelected: 'redventures.orderprocessing.v1.FieldSelected',
  FieldInputted: 'redventures.orderprocessing.v1.FieldInputted',
  FormContinued: 'redventures.orderprocessing.v1.FormContinued',
  FormSubmitted: 'redventures.orderprocessing.v1.FormSubmitted',
  ProductClicked: 'redventures.orderprocessing.v1.ProductClicked',
  ProductViewed: 'redventures.ecommerce.v1.ProductViewed',
  PageViewed: 'redventures.orderprocessing.v1.PageViewed',
  OrderSessionStarted: 'redventures.orderprocessing.v1.OrderSessionStarted',
  ClientErrored: 'frontier.clientErrored.v1',
  FormSubmittedV3: 'redventures.usertracking.v3.FormSubmitted',
};

const tagularWrapper = (...args) => {
  if (clientSide && tagular) tagular(...args);
};

export const getContext = () => {
  const processId = getOrCreateProcessId();

  const state =
    clientSide && JSON.parse(window.sessionStorage.getItem('persist:fuel'));
  const instanceId = clientSide && window._Cohesion?.webContext?.instanceId || '';
  const manualInstanceId = clientSide && window._Cohesion?.webContext?.manualInstanceId || '';

  return {
    anonymousId: state?.tracking?.anonymousId || '',
    environment: process.env.environment === 'production' ? 'production' : 'test',
    instanceId,
    manualInstanceId,
    processId,
    moduleName: 'amplify',
    orderSessionId: state?.tracking?.orderSessionId || '',
    orderSessionIdType: 'CART',
    pageReferrer: '',
    pageUrl: getCurrentUrl(),
    profileId: '',
    publishId: process.env.release || '',
    scenarioId: '',
    sessionId: state?.tracking?.sessionId || '',
    userAgent: state?.tracking?.userAgent || '',
  };
};

const getClientSessionId = () => {
  const state =
    clientSide && JSON.parse(window.sessionStorage.getItem('persist:fuel'));
  return state?.tracking?.clientSessionId || state?.tracking?.orderSessionId || '';
};

const getClientId = () => {
  const state =
    clientSide && JSON.parse(window.sessionStorage.getItem('persist:fuel'));
  return state?.tracking?.clientId || '';
};

export const elementClicked = (data, event) => {
  const { target = {} } = event;
  const payload = {
    '@type': EVENTS.ElementClicked,
    atomContext: getContext(),
    webElement: {
      elementType: data?.elementType || target?.type?.toUpperCase() || '',
      location: data?.location || '',
      position: data?.position || '',
      htmlId: data?.htmlID || target?.id || '',
      text: data?.text || target?.innerText || '',
    },
    actionOutcome: data?.action || '',
    outboundUrl: data?.outboundUrl || '',
  };
  tagularWrapper('beam', payload, true, false);
};

export const elementViewed = (data, event) => {
  const { target = {} } = event;
  const payload = {
    '@type': EVENTS.ElementViewed,
    atomContext: getContext(),
    webElement: {
      elementType: data?.elementType || target?.tageName || '',
      location: data?.location || '',
      position: data?.position || '',
      htmlId: data?.htmlID || target?.id || '',
      text: data?.text || target?.innerText || '',
    },
  };
  tagularWrapper('beam', payload, true, false);
};

export const productClicked = (data) => {
  const payload = {
    '@type': EVENTS.ProductClicked,
    atomContext: getContext(),
    product: {
      brand: data?.brand || '',
      category: data?.category || '',
      coupon: data?.coupon || '',
      location: data?.location || '',
      name: data?.name || '',
      position: data?.position || 0,
      price: parseInt(data?.price || 0, 10),
      productId: data?.productId || '',
      quantity: data?.quantity || 1,
      sku: data?.sku || '',
      subscription: {
        frequency: data?.frequency || '',
      },
      upc: data?.upc || '',
      variant: data?.variant || '',
    },
    actionOutcome: data?.action || '',
  };
  tagularWrapper('beam', payload, true, false);
};

export const productViewed = (data) => {
  const payload = {
    '@type': EVENTS.ProductViewed,
    product: {
      brand: data?.brand || '',
      category: data?.category || '',
      coupon: data?.coupon || '',
      location: data?.location || '',
      name: data?.name || '',
      position: data?.position || 0,
      price: parseInt(data?.price || 0, 10),
      productId: data?.productId || '',
      quantity: data?.quantity || 1,
      sku: data?.sku || '',
      subscription: {
        frequency: data?.frequency || '',
      },
      upc: data?.upc || '',
      variant: data?.variant || '',
    },
  };
  tagularWrapper('beam', payload, true, false);
};

export const fieldSelected = (data, event) => {
  const { target = {} } = event;
  const payload = {
    '@type': EVENTS.FieldSelected,
    atomContext: getContext(),
    userInputField: {
      autofilled: target?.autofilled || data?.autofilled || false,
      fieldType: target?.type || data?.fieldtype || '',
      fieldName: target?.name || data?.fieldname || '',
      fieldId: target?.id || data?.fieldid || '',
      fieldLabel: target?.placeholder || data?.fieldlabel || '',
    },
  };
  tagularWrapper('beam', payload, true, false);
};

export const fieldInputted = (data, event) => {
  const { target = {} } = event;
  const payload = {
    '@type': EVENTS.FieldInputted,
    atomContext: getContext(),
    userInputField: {
      autofilled: target?.autofilled || data?.autofilled || false,
      fieldType: target?.type || data?.fieldtype || '',
      fieldName: target?.name || data?.fieldname || '',
      fieldId: target?.id || data?.fieldid || '',
      fieldLabel: target?.placeholder || data?.fieldlabel || '',
    },
  };
  tagularWrapper('beam', payload, true, false);
};

export const formSubmitted = (data) => {
  const payload = {
    '@type': EVENTS.FormSubmitted,
    atomContext: getContext(),
    formContext: {
      formType: data?.formType || '',
      formName: data?.formName || '',
      formId: data?.formId || '',
    },
    outboundUrl: data?.outboundUrl || '',
    correlationId: data?.correlationId || '',
    autoFillUsed: data?.autoFillUsed || '',
  };
  tagularWrapper('beam', payload, true, false);
};

export const formSubmittedV3 = (data) => {
  const payload = {
    '@type': EVENTS.FormSubmittedV3,
    formContext: {
      formType: data?.formType || '',
      formName: data?.formName || '',
      formId: data?.formId || '',
    },
  };
  tagularWrapper('beam', payload);
};

export const pageViewed = () => {
  const payload = {
    '@type': EVENTS.PageViewed,
    atomContext: getContext(),
  };
  tagularWrapper('beam', payload, true, false);
  tagularWrapper('pageView', false); // The third parameter tells Preamp to not redecision
};

export const pageScrolled = (data) => {
  const payload = {
    '@type': EVENTS.PageScrolled,
    atomContext: getContext(),
    scrollDistance: data?.scrollDistance,
  };
  tagularWrapper('beam', payload, true, false);
};

export const OrderSessionStarted = () => {
  const payload = {
    '@type': EVENTS.OrderSessionStarted,
    atomContext: getContext(),
  };
  tagularWrapper('beam', payload, true, false);
  setNewRelicCustomAttribute([{ name: 'orderSessionStartedFired', value: 'true' }]);
};

export const clientErrored = (data) => {
  const payload = {
    '@type': EVENTS.ClientErrored,
    context: getContext(),
    errorMessage: data?.errorMessage || '',
    errorClass: data?.errorClass?.toUpperCase() || 'ERROR',
    clientId: getClientId(),
    clientSessionId: getClientSessionId(),
    quoteId: getQuoteId(),
  };
  tagularWrapper('beam', payload, true, false);
};

export const editPlan = (data, event) => {
  const { target = {} } = event;
  const payload = {
    '@type': EVENTS.ElementClicked,
    atomContext: getContext(),
    webElement: {
      text: data?.text || target?.innerText || '',
      location: data?.location || '',
    },
  };
  tagularWrapper('beam', payload, true, false);
};
