/* eslint max-len: 0 */
import React, { SVGProps } from 'react';

const CheckCircleBlack = (props: SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="icon icon--check-circle-black"
      {...props}
    >
      <rect x="0.5" y="0.5" width="17" height="17" rx="8.5" stroke="#141928"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M14.8337 5.5L13.8337 4.5L7.16699 11.1667L4.66699 8.66667L3.66699 9.66667L6.16699 12.1667L7.16699 13.1667L8.16699 12.1667L14.8337 5.5Z" fill="#141928"/>
    </svg>
  );
};

CheckCircleBlack.displayName = 'CheckCircleBlack';

export default CheckCircleBlack;
