/* eslint max-len: 0 */
import React, { SVGProps } from 'react';

const WholeHomeWifi = (props: SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="icon icon--whole-home-wifi"
      {...props}
    >
      <path
        d="M15.3046 3.98729C15.7314 3.72646 16.2683 3.72646 16.6951 3.98729L26.0284 9.691C26.4248 9.9332 26.6665 10.3642 26.6665 10.8287V24.6665H29.1998C29.2873 24.2357 29.3332 23.7898 29.3332 23.3331V10.8287C29.3332 9.43524 28.608 8.1422 27.419 7.41558L18.0856 1.71187C16.8052 0.929376 15.1945 0.929375 13.914 1.71187L4.5807 7.41558C3.39169 8.1422 2.6665 9.43524 2.6665 10.8287V23.3331C2.6665 23.7898 2.71241 24.2357 2.79987 24.6665H5.33317V10.8287C5.33317 10.3642 5.5749 9.9332 5.97124 9.69099L15.3046 3.98729Z"
        fill={props?.fill || 'white'}
      />
      <path
        d="M28.0003 27.3332H21.3332C21.3312 27.3332 21.3292 27.3332 21.3273 27.3331H11.3391L11.3332 27.3332H3.99937C5.21564 28.9524 7.15209 29.9998 9.33317 29.9998H22.6665C24.8476 29.9998 26.784 28.9524 28.0003 27.3332Z"
        fill={props?.fill || 'white'}
      />
      <path
        d="M6.99268 12.9932C9.01136 11.1347 11.7065 9.99981 14.6667 9.99981H17.3334C20.2936 9.99981 22.9888 11.1347 25.0074 12.9932L23.4433 15.1866C21.8764 13.629 19.7173 12.6665 17.3334 12.6665H14.6667C12.2828 12.6665 10.1237 13.629 8.55685 15.1866L6.99268 12.9932Z"
        fill={props?.fill || 'white'}
      />
      <path
        d="M21.7012 17.6294C20.4789 16.2226 18.6767 15.3331 16.6667 15.3331H15.3334C13.3235 15.3331 11.5213 16.2226 10.299 17.6294L11.9236 19.9075C12.6274 18.763 13.8913 17.9998 15.3334 17.9998H16.6667C18.1089 17.9998 19.3728 18.763 20.0765 19.9075L21.7012 17.6294Z"
        fill={props?.fill || 'white'}
      />
      <path
        d="M13.9998 22.6665C13.9998 21.5619 14.8953 20.6665 15.9998 20.6665C17.1044 20.6665 17.9998 21.5619 17.9998 22.6665C17.9998 23.771 17.1044 24.6665 15.9998 24.6665C14.8953 24.6665 13.9998 23.771 13.9998 22.6665Z"
        fill={props?.fill || 'white'}
      />
    </svg>
  );
};

WholeHomeWifi.displayName = 'WholeHomeWifi';

export default WholeHomeWifi;
