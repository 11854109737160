import {
  FieldError,
  UseFormRegister,
  Control,
  UseFormGetValues,
  UseFormClearErrors,
  UseFormSetError,
} from 'react-hook-form';
import { Disclosure } from './order';
import { NoArgVoid } from './common';
import { Modals } from './products';
import { Address } from '~/types/address';

// Core Data Types
export type Contact = {
  accountUuid?: string;
  firstname: string;
  lastname: string;
  email: string;
  confirmEmail?: string;
  phone: string;
  tcpaMarketing?: boolean;
  tcpaService?: boolean;
  gmtOffset?: number;
  depositOptedIn?: boolean;
  creditFreezeLifted?: boolean;
};

export interface ContactResponse {
  success: boolean;
  contactVerifyComplete?: boolean;
}

// Credit Freeze
export type CreditFreeze = {
  creditFreezeStage: boolean;
  creditFreezeLifted: boolean;
  creditFreezeSubmissionCount: number;
};

export interface VerifyPhoneResponse {
  success: boolean;
}

// Credit
export type Credit = {
  month?: number;
  day?: number;
  year?: number;
  dob?: string;
  ssn: string;
  previousAddress?: string;
};

export type ContactCredit = Contact & Credit;

export interface GetConfigurationsResponse {
  loading?: boolean;
  productId: string;
  configurations: Configuration[];
}

export type BillingSummary = {
  monthlyCharges: number;
  monthlyChargesACH: number;
  monthlyChargesDebit: number;
  monthlyChargesCredit: number;
  monthlyChargesNoAP: number;
  monthlyStrikethrough: number;
  oneTimeCharges: number;
  lineItems: BillingSummaryLineItem[];
  routerType: BillingSummaryLineItem[];
  totalUpfrontCharges: number;
  estimatedMonthlyTax: number;
  depositAmount: number;
  outstandingBalance: number;
  activationFee: BillingSummaryLineItem;
};

export type BillingSummaryLineItem = {
  amount: number;
  productId: string;
  name: string;
  unit: string;
  originalAmount?: number;
  displayName: string;
  category: string;
  displayStrikethrough: boolean;
};

export type IdentityQuestion = {
  question: string;
  questionNumber: number;
  answerChoices: Record<string, unknown>[];
};

// Form Error Types
export type ContactErrors = {
  firstname?: FieldError;
  lastname?: FieldError;
  email?: FieldError;
  confirmEmail?: FieldError;
  phone?: FieldError;
};

export type CreditErrors = {
  month?: FieldError;
  day?: FieldError;
  year?: FieldError;
  dob?: FieldError;
  ssn?: FieldError;
  previousAddress?: FieldError;
};

export type UseFormClearCreditErrors<T> = (fields: (keyof T)[]) => void;

// Form Props and Return Types
export interface ContactCreditReturnType {
  apiVersion: string;
  contactSubmitted: boolean;
  activeStep: number;
  activeModal: Modals;
  setActiveModal: (key?: Modals) => void;
  verifyPhone: {
    data: boolean;
    error: string | Error | VerifyPhoneResponse;
    loading: boolean;
    handleVerifyPhone: (data) => void;
  };
  formProps: {
    register: UseFormRegister<Contact & Credit>;
    setError: UseFormSetError<Contact & Credit>;
    clearErrors: UseFormClearErrors<Contact & Credit>;
    getValues: UseFormGetValues<Contact & Credit>;
    handleSubmit: NoArgVoid;
    handleSubmitGenerateCode: NoArgVoid;
    handleContactGenerateCode?: NoArgVoid;
    setValue: (name: string, value: string) => void;
    errors: ContactErrors & CreditErrors;
    control: Control<Contact & Credit>;
    formValid: boolean;
    loading: boolean;
  };
  contact: { loading: boolean };
  credit: { loading: boolean };
  creditBypassSecondPass?: boolean;
  selectedPreviousAddress?: Address;
  setSelectedPreviousAddress?: (address: Address) => void;
}

export type ContactCreditRegister = UseFormRegister<Contact | Credit>;

// Component Props
export interface DOBBaseProps
  extends Pick<ContactCreditReturnType['formProps'], 'register' | 'setError' | 'clearErrors' | 'getValues' | 'errors'> {
  contact?: {
    labels?: {
      dateOfBirth?: string;
    };
    verifyPhone?: {
      enabled?: boolean;
    };
  };
  credit?: {
    labels?: {
      dateOfBirth?: string;
    };
    dateOfBirthSubLabel?: string;
    dateOfBirthTooltip?: string;
  };
}

export interface DOBMultiInputProps extends DOBBaseProps {
  control: ContactCreditReturnType['formProps']['control'];
}

export type DOBSingleInputProps = DOBBaseProps;

export interface ContactCreditFormProps
  extends Pick<
      ContactCreditReturnType['formProps'],
      'register' | 'setError' | 'clearErrors' | 'getValues' | 'setValue' | 'errors' | 'control' | 'handleSubmit'
    >,
    Pick<
      ContactCreditReturnType,
      'apiVersion' | 'creditBypassSecondPass' | 'selectedPreviousAddress' | 'setSelectedPreviousAddress'
    > {
  title?: string;
  header?: {
    copy?: string;
    tooltip?: string;
  };
  contact?: {
    labels?: {
      firstName?: string;
      lastName?: string;
      emailAddress?: string;
      phoneNumber?: string;
      dateOfBirth?: string;
    };
    emailAddressTooltip?: string;
    phoneNumberTooltip?: string;
    verifyPhone?: {
      enabled?: boolean;
    };
  };
  credit?: {
    dobSingleInput?: boolean;
    labels?: {
      dateOfBirth?: string;
      socialSecurityNumber?: string;
      previousAddress?: string;
    };
    phoneSubLabel?: string;
    dateOfBirthSubLabel?: string;
    dateOfBirthTooltip?: string;
    softCreditCheckCopy?: {
      defaultCopy?: string;
      backgroundColor?: string;
      200?: { withVas?: string; withoutVas?: string };
      500?: { withVas?: string; withoutVas?: string };
      1000?: { withVas?: string; withoutVas?: string };
      2000?: { withVas?: string; withoutVas?: string };
      5000?: { withVas?: string; withoutVas?: string };
      7000?: { withVas?: string; withoutVas?: string };
    };
    socialSecurityNumberTooltip?: string;
    previousAddressSubLabel?: string;
    previousAddressTooltip?: string;
    verify?: {
      copy?: string;
      emphasized?: string;
    };
    verifyBypass?: {
      copy?: string;
      emphasized?: string;
    };
    verifySsnOptionalBypass?: {
      copy?: string;
      emphasized?: string;
    };
  };
  bypassCreditCheck?: boolean;
  depositOptInEnabled?: boolean;
  phoneNumberHeaderEnabled?: boolean;
  liftCreditFreezeModalv2Enabled?: boolean;
  enableOperationalSms?: boolean;
}

// API Response Types
export interface ContactResponse {
  success: boolean;
  contactVerifyComplete?: boolean;
}

export interface VerifyPhoneResponse {
  success: boolean;
}

export interface CreditResponse {
  success?: boolean;
  loading?: boolean;
  billingSummary?: BillingSummary;
  telephoneNumber?: string;
  disclosures?: Disclosure[];
  identityVerification?: IdentityQuestion[];
  frapiErrorCode?: string;
  frapiErrorMessage?: string;
  verifySubmitted?: boolean;
}

export interface APIError {
  isHardStop: boolean;
  userErrorMessage: string;
  errorMessage?: string;
  acquisitionError?: string;
  frapiErrorCode?: string;
  frapiErrorMessage?: string;
  frapiErrorEndpoint?: string;
}

// Redux Types
export enum ActionTypes {
  SET_CONTACT = 'contact/set',
  SET_CREDIT = 'credit/set',
  SET_ONETIMEPASSWORD = 'otp/set',
  SET_CREDIT_FREEZE = 'creditFreeze/set',
}

export interface SetContactAction {
  type: ActionTypes.SET_CONTACT;
  contact: Contact;
}

export interface SetCreditAction {
  type: ActionTypes.SET_CREDIT;
  credit: CreditResponse;
}

export interface SetOneTimePasswordAction {
  type: ActionTypes.SET_ONETIMEPASSWORD;
  otp: OneTimePassword;
}

// Helper Types
export type Configuration = {
  name: string;
  active: boolean;
};

export type OneTimePassword = {
  success: boolean;
  codesGenerated: number;
  codeVerifyAttempts: number;
  verificationError: boolean;
  verificationRequiredFromQuote: boolean;
  attemptsPerCode: {
    1: 0;
    2: 0;
  };
};

export interface SetCreditFreezeAction {
  type: ActionTypes.SET_CREDIT_FREEZE;
  creditFreeze: CreditFreeze;
}

export type Actions = SetContactAction | SetCreditAction | SetOneTimePasswordAction | SetCreditFreezeAction;
