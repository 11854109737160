import { CartConfigEditableLoader } from '~/types/editable-loader';

export const cartConfigEditableLoaderDefaultValue: CartConfigEditableLoader = {
  unservRedirectEnabled: false,
  _: {
    enabled: false,
    theme: 'theme-2',
    icon: 'confirmationCheck',
    heading: 'Redirecting you to AT&T',
    subheading: 'This may take up to one minute. Please do not refresh the page.',
    timer: 8,
  },
  '_my-custom-quote': {
    enabled: false,
    theme: 'theme-2',
    icon: 'confirmationCheck',
    heading: 'Redirecting you to AT&T',
    subheading: 'This may take up to one minute. Please do not refresh the page.',
    timer: 8,
  },
  '_plan-package': {
    enabled: false,
    theme: 'theme-1',
    icon: 'confirmationCheck',
    heading: 'Confirming your order',
    subheading: 'This may take up to one minute. Please do not refresh the page.',
    timer: 8,
  },
  '_contact-credit': {
    enabled: false,
    theme: 'theme-1',
    icon: 'confirmationCheck',
    heading: 'Confirming your order',
    subheading: 'This may take up to one minute. Please do not refresh the page.',
    timer: 8,
  },
  _order: {
    enabled: false,
    theme: 'theme-1',
    icon: 'confirmationCheck',
    heading: 'Confirming your order',
    subheading: 'This may take up to one minute. Please do not refresh the page.',
    timer: 8,
  },
  _checkout: {
    enabled: false,
    theme: 'theme-1',
    icon: 'confirmationCheck',
    heading: 'Confirming your order',
    subheading: 'This may take up to one minute. Please do not refresh the page.',
    timer: 8,
  },
  facts: {
    fact1: {
      fact: 'Every Frontier Fiber plan provides equally fast upload and download speeds so you experience the full power of your internet.',
    },
    fact2: {
      fact: 'The majority of US consumers consider fiber the best internet delivery method in terms of speed and reliability.',
    },
    fact3: {
      fact: 'Fiber is better for the environment -- it gives off 34% less greenhouse gas emissions than older tech.',
    },
    fact4: {
      fact: '',
    },
    fact5: {
      fact: '',
    },
    fact6: {
      fact: '',
    },
    fact7: {
      fact: '',
    },
    fact8: {
      fact: '',
    },
  },
  progressSteps: {
    step1Title: 'Getting your plan ready',
    step2Title: 'Finalizing appointment details',
    step3Title: 'Processing payment',
    step4Title: 'Submitting your order',
  },
  unservRedirectProvider: {
    atT: {
      heading:
        'Frontier Fiber is not available in your area.<br /> Don’t worry! We’ll transfer you to AT&T Fiber<sup>&#174;</sup> for the next available plan.',
      subheading: 'This may take up to one minute. Please do not refresh the page.',
    },
    verizon: {
      heading:
        'Frontier Fiber is not available in your area.<br /> Don’t worry! We’ll transfer you to Verizon for the next available plan.',
      subheading: 'This may take up to one minute. Please do not refresh the page.',
    },
    centuryLink: {
      heading:
        'Frontier Fiber is not available in your area.<br /> Don’t worry! We’ll transfer you to CenturyLink for the next available plan.',
      subheading: 'This may take up to one minute. Please do not refresh the page.',
    },
  },
};
