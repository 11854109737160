import { useEffect } from 'react';
import { trackEvent } from '~/helpers/tracking';
import { getCookie } from '~/helpers/getCookie';

const Localize = (): JSX.Element => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://global.localizecdn.com/localize.js';
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);

    const widget = document.createElement('script');

    script.onload = () => {
      widget.async = true;
      widget.defer = true;
      widget.innerHTML = `!function(a){if(!a.Localize){a.Localize={};for(var e=["translate","untranslate","phrase","initialize","translatePage","setLanguage","getLanguage","getSourceLanguage","detectLanguage","getAvailableLanguages","untranslatePage","bootstrap","prefetch","on","off","hideWidget","showWidget", "vueSafe"],t=0;t<e.length;t++)a.Localize[e[t]]=function(){}}}(window);
      Localize.on("setLanguage", function(languageData) {
        const localizedLoadedEvent = new CustomEvent("localizedTriggered", {detail: {language: languageData.to}});
        document.dispatchEvent(localizedLoadedEvent);
      });

      Localize.initialize({
      key: 'nH1lia9ugiilt',
      rememberLanguage: true
      });
      
      Localize.setLanguage('${getCookie('localizeLanguage') || 'en'}');`;
    };

    document.body.appendChild(widget);

    trackEvent({
      action: 'elementViewed',
      data: {
        elementType: 'button',
        position: 'Localize Widget',
        location: window.location.href,
        text: `Localize widget loaded - ${getCookie('localizeLanguage') || 'en'}`,
      },
    });

    return () => {
      document.body.removeChild(script);
      document.body.removeChild(widget);
    };
  }, []);

  useEffect(() => {
    const localizedTriggeredHandler = (event: CustomEvent) => {  
      trackEvent({
        action: 'elementClicked',
        data: {
          elementType: 'button',
          position: 'Localize Widget',
          location: window.location.href,
          text: event.detail.language
        },
      });

      // adobe
      // partner specs require only the language code's first 2 letters (en, es)
      trackEvent({
        action: 'localizeClicked',
        data: {
          elementType: 'BUTTON',
          detail: {
            language: event.detail.language.substring(0, 2) // es-US => es
          },
        },
      });
    };

    document.addEventListener("localizedTriggered", localizedTriggeredHandler);

    return () => {
      document.removeEventListener("localizedTriggered", localizedTriggeredHandler);
    };
  }, []);
    

  return null;
};

Localize.displayName = 'Localize';

export default Localize;
