export const DEFAULT_FUSE_NUMBER = {
  frdc: '844-362-2972',
  infr: '877-324-5847',
  frin: '877-617-1979',
  ac: '877-899-8584',
};

export const UNSERV_VOICE_NUMBER = '877-521-1535';

export const EXISTING_SERVICE_NUMBER = '800-921-8101';

export const API_RETRY_MAX = 3;
export const API_RETRY_DELAY = 150; //ms

export const DEFAULT_HOURS = ['8am - 10pm ET, Monday to Friday', '8am - 9pm ET, Saturday', '9am - 9pm ET, Sunday'];

export const DEFAULT_ERROR_MESSAGE = `Sorry, we're having some technical difficulties. Call ##phoneNumber## and one of our sales experts will help you complete your order.`;

export const DEFAULT_API_VERSION = 'v2';

// Monarch
export const MONARCH_SOURCE_ID = '088adec0-65af-49ae-98b3-337ab58d4f6b';
export const MONARCH_TOKEN = 'e9584f8a-2739-486b-a11e-2b176f158cd2';
export enum MONARCH_RULESETS {
  GLOBAL = 'be301b7e-8f6a-46ba-95d3-551f55c02888',
  GLOBAL_CDAPI = 'f575340e-33a5-470e-9273-4529cc4766c6'
}

export const DOMAINS = {
  frdc: [
    'localhost',
    'rv-local.ftr.com',
    'fuelcartnonprod.z19.web.core.windows.net',
    'qat01.frontier.com',
    'qat02.frontier.com',
    'qat03.frontier.com',
    'qat04.frontier.com',
    'uat.frontier.com',
    'frontier.com',
    'www.frontier.com',
    'ctest.frontier.com',
    'pprd.frontier.com',
  ],
  infr: [
    'rv-local.infr.com',
    'internet.frontier.com',
    'internet-frontier.frontier-development.redventures.io',
    'qat02.frontier.com',
    'ctest.frontier.com',
    'preview.frontier-development.redventures.io',
  ],
  frin: [
    'rv-local.frin.com',
    'frontierinternet.com',
    'www.frontierinternet.com',
    'frontierinternet.frontier-development.redventures.io',
  ],
  ac: ['rv-local.ac.com', 'allconnect.com', 'www.allconnect.com', 'dev.allconnect.com'],
};

export const DOMAINS_EXTERNAL = {
  signin: 'https://myftr.page.link/FTRAppSignIn'
};

export const DEV_WRITE_AND_SOURCE_KEYS = {
  writeKey: 'wk_24yH5wA4KRm7uk2m6DWo37rMSik',
  sourceKey: 'src_24yH5vGUPks7O2KRIGUNCHZrR19',
};

export const AC_DEV_WRITE_AND_SOURCE_KEYS = {
  affiliateId: 'RED VENTURES_MOVE',
  writeKey: 'wk_1NT28Xs9LiZuknWRbR9cXPtucjV',
  sourceKey: 'src_1NT28RdkYcgBjTRFLTgYcWcrH1n',
};

export const AFFILIATE_IDS = {
  rvfrdccart: '701111',
  rvseocart: '701115',
  rvpdmdcart: '701113',
  rvrvprop: '701117',
  rvfrdcc2fcart: '701180',
  rvfrdcamexcart: '701302',
};

export const USER_IDS = {
  FRDC: 'rvfrdccart',
  INFR: 'rvseocart',
  FRIN: 'rvpdmdcart',
  AC: 'rvrvprop',
  C2FL: 'rvfrdcc2fcart',
  AMEX: 'amexfrdc',
};

export const CHANNEL_IDS = {
  rvfrdccart: '552',
  rvseocart: '552',
  rvpdmdcart: '553',
  rvrvprop: '217',
  rvfrdcc2fcart: '552',
  rvfrdcamexcart: '552',
};


export const DEFAULT_TCPA_SERVICE_SETTING = true;
