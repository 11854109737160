import { Actions, ActionTypes } from '~/types/products';
import { ReduxState } from '~/types/redux';
import assign from 'lodash/assign';

const products = (state: ReduxState, action: Actions): ReduxState => {
  switch (action.type) {
    case ActionTypes.SET_PRODUCTS:
      return assign({}, state, {
        products: {
          ...state.products,
          available: action.available,
          offerLevelSweeteners: action.offerLevelSweeteners,
        },
      });
    case ActionTypes.SELECT_PRODUCT:
      return assign({}, state, {
        products: {
          ...state.products,
          selected: {
            ...action.selectedState,
          },
        },
      });
    case ActionTypes.CLEAR_SELECT_PRODUCT:
      return assign({}, state, {
        products: {
          ...state.products,
          selected: undefined,
        },
      });
    case ActionTypes.SET_OFFER_TYPE:
      return assign({}, state, {
        products: {
          ...state.products,
          offerType: action.offerType,
        },
      });

    case ActionTypes.SET_BROADBAND_TYPE:
      return assign({}, state, {
        products: {
          ...state.products,
          broadbandType: action.broadbandType,
        },
      });
    case ActionTypes.SET_SELECTED_SWEETENER:
      return assign({}, state, {
        products: {
          ...state.products,
          planPageSelections: {
            ...(state?.products?.planPageSelections || {}),
            selectedSweetener: action.selectedSweetener,
          },
        },
      });
    case ActionTypes.SET_NETFLIX_BUNDLE:
      return assign({}, state, {
        products: {
          ...state.products,
          planPageSelections: {
            ...(state?.products?.planPageSelections || {}),
            selectedNetflix: action.selectedNetflix,
          },
        },
      });
    case ActionTypes.SET_VRC_MODAL_SHOWN:
      return assign({}, state, {
        products: {
          ...state.products,
          planPageSelections: {
            ...(state?.products?.planPageSelections || {}),
            vrcModalShown: action.vrcModalShown,
          },
        },
      });
    case ActionTypes.SET_PHONE_SELECTION:
      return assign({}, state, {
        products: {
          ...state.products,
          planPageSelections: {
            ...(state?.products?.planPageSelections || {}),
            phoneSelection: action.phoneSelection,
          },
        },
      });
    case ActionTypes.SET_FIRST_AVAILABLE:
      return assign({}, state, {
        products: {
          ...state.products,
          firstAvailable: action.firstAvailable,
        },
      });
    case ActionTypes.SET_NDI_BANNER:
      return assign({}, state, {
        products: {
          ...state.products,
          ndiBanner: action.ndiBanner,
        },
      });
    case ActionTypes.SET_LANDING_PAGE_PRODUCT:
      return assign({}, state, {
        products: {
          ...state.products,
          landingPageProduct: action.landingPageProduct,
        },
      });
    case ActionTypes.SET_LANDING_PAGE_PRODUCT_AVAILABLE:
      return assign({}, state, {
        products: {
          ...state.products,
          landingPageProductAvailable: action.landingPageProductAvailable,
        },
      });
    case ActionTypes.SET_OFFER_OBJECTS:
      return assign({}, state, {
        products: {
          ...state.products,
          offerObjects: action.offerObjects,
        },
      });
    case ActionTypes.SET_OFFER_MODAL_SELECTED:
      return assign({}, state, {
        offerModalSelected: action.product,
      });
    case ActionTypes.SET_INCLUDED_WITH_WHOLE_HOME_WIFI_PLUS:
      return assign({}, state, {
        products: {
          ...state.products,
          cardConsolidation: {
            ...state.products.cardConsolidation,
            includedWithWholeHomeWifiPlus: action.includedWithWholeHomeWifiPlus,
          },
        },
      });
    case ActionTypes.SET_CARD_CONSOLIDATION:
      return assign({}, state, {
        products: {
          ...state.products,
          cardConsolidation: {
            ...state.products.cardConsolidation,
            isEnabled: action.isEnabled,
          },
        },
      });
    case ActionTypes.SET_CARD_CONSOLIDATION_MODAL:
      return assign({}, state, {
        products: {
          ...state.products,
          cardConsolidation: {
            ...state.products.cardConsolidation,
            modalOptions: {
              ...state.products?.cardConsolidation?.modalOptions,
              checked: action.checked,
              option: action.option,
              selectedType: action.selectedType,
            },
          },
        },
      });
    case ActionTypes.SET_VAS_SETTINGS:
      return assign({}, state, {
        products: {
          ...state.products,
          vasSettings: {
            hasVas: action.hasVas,
            hasVasVersion: action.hasVasVersion,
            hasVasSubmitTextButton: action.hasVasSubmitTextButton,
          },
        },
      });
    case ActionTypes.SET_SALES_JOURNEY:
      return assign({}, state, {
        products: {
          ...state.products,
          salesJourney: action.salesJourney,
        },
      });
    default:
      return state;
  }
};

export default products;
