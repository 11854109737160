import Cookie from 'js-cookie';
import { v4 as uuid } from 'uuid';
import { PAGE_ROUTES } from '~/constants/pages';
import { isBeforeDate } from '~/helpers/isBeforeDate';
import clientSide from '~/helpers/clientSide';

/**
 * Determines whether the user qualifies for the resume modal to activate.
 * @returns {boolean} - True if the resume is eligible, false otherwise.
 */
export const isResumeEligible = (): boolean => {
  if (typeof window === 'undefined') return false;

  const state = clientSide && JSON.parse(window.sessionStorage?.getItem('persist:fuel'));

  const address = state?.address?.install?.address;
  const quoteCreated = Cookie.get('quoteCreated');
  const quoteId = Cookie.get('quoteId');
  const dateIsBefore = isBeforeDate(quoteCreated, state?.monarch?.cartQuoteCutoffDate);
  const declinedResume = window.sessionStorage?.getItem('declinedResume') || state?.resume?.acceptedResume === false;
  const completedResume = state?.resume?.completed === true;

  const isEligible = !!quoteId && !completedResume && !declinedResume && dateIsBefore && !address;
  return isEligible;
};

/**
 * Determines the cart flow based on the current path.
 * @param path - The current window path.
 * @returns A string representing the cart experience or null if the path is a checkout page.
 */
export const getCartFlowFromPath = (): string | null => {
  if (typeof window === 'undefined') {
    return null;
  }

  const path = window.location.pathname;
  switch (true) {
    // Checkout & resume pages: continue previous flow
    case path.includes(PAGE_ROUTES.CONTACT_CREDIT):
    case path.includes(PAGE_ROUTES.CHECKOUT):
    case path.includes(PAGE_ROUTES.RESUME):
    case path.includes(PAGE_ROUTES.BILLING_SUMMARY):
    case path.includes(PAGE_ROUTES.CONFIRMATION):
      return null;
    // GDT path
    case path.includes(PAGE_ROUTES.MY_CUSTOM_QUOTE):
    case path.includes(PAGE_ROUTES.MY_CUSTOM_QUOTE_PLAN_PACKAGE):
      return 'gdtCartExperience';
    // AI path
    case path.includes(PAGE_ROUTES.CUSTOMIZE):
    case path.includes(PAGE_ROUTES.RECOMMENDATION):
    case path.includes(PAGE_ROUTES.BUILD):
      return 'aiCartExperience';
    // Default to BAU
    default:
      return 'bauCartExperience';
  }
};

/**
 * Updates the cart flow cookie based on the current path and returns the current cart flow,
 * the flow will not be updated if the resume modal is active or the path is a checkout/resume page.
 * @returns The current cart flow as stored in the cookie, defaulting to 'bauCartExperience'.
 */
export const getCartFlowAndUpdateCookie = (): string => {
  const currentCookieValue = Cookie.get('cartFlow') || 'bauCartExperience';

  const isEligibleForResume = isResumeEligible();
  const isResumeActive = window?.sessionStorage?.getItem('resumeModalActive') === 'true';

  if (isEligibleForResume || isResumeActive) {
    return currentCookieValue;
  }

  const flow = getCartFlowFromPath();

  if (flow === null) {
    return currentCookieValue;
  } else {
    Cookie.set('cartFlow', flow);
    return flow;
  }
};

/**
 * Returns a prefix based on the cart flow.
 * @param cartFlow - The current cart flow.
 * @returns The prefix string for the processId.
 */
const getExperiencePrefix = (cartFlow: string): string => {
  if (cartFlow === 'aiCartExperience') return 'NGC-';
  if (cartFlow === 'gdtCartExperience') return 'GDT-';
  return 'BAU-';
};

/**
 * Retrieves the existing processId from the cookie or creates a new one with the proper prefix.
 * @param cartFlow - The current cart flow.
 * @returns A processId string with the appropriate prefix.
 */
export const getOrCreateProcessId = (): string => {
  const cartFlow = getCartFlowAndUpdateCookie();
  const expectedPrefix = getExperiencePrefix(cartFlow);
  const existingProcessId = Cookie.get('processId');

  if (existingProcessId && existingProcessId.startsWith(expectedPrefix)) {
    return existingProcessId;
  }
  const processId = `${expectedPrefix}${uuid()}`;
  Cookie.set('processId', processId);
  return processId;
};
