import { FC, useEffect, useState } from 'react';
import useLogRocket from '~/hooks/useLogrocket';
import Head from 'next/head';
import { AppProps } from 'next/app';
import FuelProvider from '~/providers/FuelProvider';
import Header from '~/components/Header';
import Footer from '~/components/Footer';
import useAppSetup from '~/hooks/useAppSetup';
import ErrorBoundary from '~/components/ErrorBoundary';
import Notifications from '~/components/Notifications';
import SFCONotifications from '~/components/SingleFormCheckout/Notifications';
import { Container } from '~/components/Layout/LayoutStyled';
import Loader from '~/components/Loader';
import 'react-tippy/dist/tippy.css';
import { logError, setMonarchMonitoring } from '@red-digital/bricks';
import { PAGE_ROUTES } from '~/constants/pages';
import listOfMonarchComponents from '../../monarch/listOfMonarchComponents';
import SessionModal from '~/components/Modal/screens/SessionExpired';
import '~/styles/scss/base.scss';
import '~/styles/bricks/all.scss';
import { withCohesion } from '../providers/CohesionProvider';
import Localize from '~/components/Localize/index';
import StickyBanner from '~/components/Banner/StickyBanner';
import Cohesion from '~/components/Cohesion';
import { spanishPreferred } from '~/helpers/spanishPreferred';

const App: FC<AppProps> = ({ Component, pageProps }) => {
  const { store, production, router } = useAppSetup();
  const { pathname: currentPage } = router;
  const showHeaderAndFooter = currentPage !== PAGE_ROUTES.BUY_FIBER;

  useEffect(() => {
    setMonarchMonitoring({ hasUpdatedSnippet: true });

    window?.cohesion('error', function () {
      console.log('debug: Cohesion failed to load');
      logError('cohesion:failed: Cohesion failed to load');
    });

    window?.cohesion('monarch:done', function (err, ) {
      if (err) {
        console.error('Monarch evaluation failed', err);
        logError(`monarch:evaluation: failed - ${err}`);
        return;
      }
    })
  }, []);

  const [ stickyBannerProps, setStickyBannerProps ] = useState(null);

  useLogRocket();

  return (
    <FuelProvider store={store} production={production} router={router}>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Head>
      <Cohesion />
      { spanishPreferred() && process.env.environment === 'production' && <Localize /> }
      {stickyBannerProps && <StickyBanner { ...stickyBannerProps } />}
      {showHeaderAndFooter && <Header />}
      <ErrorBoundary production={production} number={store?.getState()?.callInNumber}>
        <Container>
          <SFCONotifications />
          <Notifications />
        </Container>
        <Component {...pageProps} stickyBannerProps={stickyBannerProps} setStickyBannerProps={setStickyBannerProps} />
        {showHeaderAndFooter && <Footer />}
        <Loader />
      </ErrorBoundary>
      <SessionModal />
    </FuelProvider>
  );
};

App.displayName = 'App';

export default withCohesion({
  App,
  components: listOfMonarchComponents,
});

