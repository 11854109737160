import { Actions, ActionTypes } from '~/types/contact-credit';
import { ReduxState } from '~/types/redux';
import assign from 'lodash/assign';

const contactCredit = (state: ReduxState, action: Actions): ReduxState => {
  switch (action.type) {
    case ActionTypes.SET_CONTACT:
      return assign({}, state, {
        contact: action.contact,
      });
    case ActionTypes.SET_CREDIT:
      return assign({}, state, {
        credit: action.credit,
      });
    case ActionTypes.SET_ONETIMEPASSWORD:
      return assign({}, state, {
        otp: action.otp,
      });
    case ActionTypes.SET_CREDIT_FREEZE:
      return assign({}, state, {
        creditFreeze: action.creditFreeze,
      });
    default:
      return state;
  }
};

export default contactCredit;
