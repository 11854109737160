/* eslint max-len: 0 */
import React, { SVGProps } from 'react';

const WifiLock = (props: SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" className="icon icon--wifiLock" {...props}>
      <path fillRule="evenodd" clipRule="evenodd" d="M15.332 1.3335C11.6501 1.3335 8.66536 4.31826 8.66536 8.00016V8.50016L8.66536 11.5587C6.67269 12.7114 5.33203 14.8659 5.33203 17.3335V24.0002C5.33203 27.6821 8.3168 30.6668 11.9987 30.6668H19.9987C23.6806 30.6668 26.6654 27.6821 26.6654 24.0002V17.3335C26.6654 14.8659 25.3247 12.7114 23.332 11.5587V8.00016C23.332 4.31827 20.3473 1.3335 16.6654 1.3335H15.332ZM11.1934 10.715C11.4574 10.6832 11.7261 10.6668 11.9987 10.6668H20.7987V8.00016C20.7987 5.79102 18.8745 3.7335 16.6654 3.7335H15.332C13.1229 3.7335 11.1987 5.79102 11.1987 8.00016V8.50016H11.1934V10.715ZM24.532 17.3335C24.532 15.1244 22.2078 12.8002 19.9987 12.8002H11.9987C9.78956 12.8002 7.46536 15.1244 7.46536 17.3335V24.0002C7.46536 26.2093 9.78956 28.2668 11.9987 28.2668H19.9987C22.2078 28.2668 24.532 26.2093 24.532 24.0002V17.3335Z" fill="white"/>
      <g clipPath="url(#clip0_8269_7585)">
      <path d="M9.86768 17.3455C11.1854 16.0802 12.919 15.312 14.8183 15.312H16.9517C18.851 15.312 20.5846 16.0802 21.9023 17.3455L21.2839 18.2975C20.1441 17.1613 18.6227 16.4676 16.9517 16.4676H14.8183C13.1473 16.4676 11.6259 17.1613 10.4861 18.2975L9.86768 17.3455Z" fill="white"/>
      <path d="M13.1341 22.3737L12.4896 21.3816C13.1744 20.4968 14.2024 19.9342 15.3517 19.9342H16.4183C17.5676 19.9342 18.5956 20.4968 19.2804 21.3816L18.6359 22.3737C18.1575 21.5995 17.3429 21.0898 16.4183 21.0898H15.3517C14.4271 21.0898 13.6125 21.5995 13.1341 22.3737Z" fill="white"/>
      <path d="M16.9517 18.7787C18.1667 18.7787 19.2632 19.3289 20.0402 20.2119L20.6637 19.2522C19.7036 18.2438 18.3945 17.6231 16.9517 17.6231H14.8183C13.3755 17.6231 12.0664 18.2438 11.1063 19.2522L11.7298 20.2119C12.5068 19.3289 13.6033 18.7787 14.8183 18.7787H16.9517Z" fill="white"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M14.0185 24.2676C14.0185 23.3103 14.7348 22.5342 15.6185 22.5342H16.1518C17.0354 22.5342 17.7518 23.3103 17.7518 24.2676V24.8453C17.7518 25.8026 17.0354 26.5787 16.1518 26.5787H15.6185C14.7348 26.5787 14.0185 25.8026 14.0185 24.8453V24.2676ZM15.0851 24.2676C15.0851 23.9485 15.3239 23.6898 15.6185 23.6898H16.1518C16.4463 23.6898 16.6851 23.9485 16.6851 24.2676V24.8453C16.6851 25.1644 16.4463 25.4231 16.1518 25.4231H15.6185C15.3239 25.4231 15.0851 25.1644 15.0851 24.8453V24.2676Z" fill="white"/>
      </g>
      <defs>
      <clipPath>
      <rect width="12.8" height="13.8667" fill="white" transform="translate(9.60107 13.8672)"/>
      </clipPath>
      </defs>
    </svg>
  );
};

WifiLock.displayName = 'WifiLock';

export default WifiLock;
