import React, { SVGProps } from 'react';

const ArrowLeft = (props: SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg width="42" height="28" viewBox="0 0 42 28" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.82866 16.5L16.4144 25.0857L13.586 27.9141L0.171753 14.4999L14.086 0.585693L16.9144 3.41412L7.82855 12.5L41.5 12.5V16.5L7.82866 16.5Z"
        fill="currentColor"
      />
    </svg>
  );
};

ArrowLeft.displayName = 'ArrowLeft';

export default ArrowLeft;
