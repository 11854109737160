import { Address } from './address';

export type NoArgVoid = () => void;

export type RecordUnknown = Record<string, unknown>;

export interface IPromoBanner {
  enabled?: boolean;
  modalEnabled?: boolean;
  attachedToCard?: string;
  title?: string;
  disclaimer?: string;
  tooltip?: string;
  legalKey?: string;
  detailsTooltip?: {
    enabled?: boolean;
    title?: string;
    tooltip?: string;
  };
}

export interface BaseResponse {
  success: boolean;
  isHardStop?: boolean;
  userErrorMessage?: string;
  errorMessage?: string;
  acquisitionError?: string;
  frapiErrorCode?: string;
  frapiErrorMessage?: string;
  frapiErrorEndpoint?: string;
}

export interface InstallationOptions {
  selfInstallEnabled?: boolean;
  selectInstallationOptionsLoading?: boolean;
  selectedInstallationOption?: string;
  installPreselected?: boolean;
  availableInstallOptions?: {
    expertInstallAvailable?: boolean;
    expertInstallCharge?: string;
    selfInstallAvailable?: boolean;
    selfInstallCharge?: string;
  };
}

export interface DiscountOptions {
  name: string;
  sku: string;
  category: string;
  type: string;
  value: number;
  ftrTrackingKey: string;
  active: boolean;
}

interface Window {
  end: string;
  scheduleId: string;
  start: string;
}

export interface AvailabilityResponse {
  success?: boolean;
  resultSize?: number;
  loading?: boolean;
  appointmentMap?: {
    [key: string]: Window[];
  };
}

export type ReactSelectOption = {
  value: string;
  label: string;
};

export type Tracking = {
  [key: string]: string;
};

export type CustomOffers = {
  [key: string]: string | number | boolean;
};

export interface C2F {
  enabled?: boolean;
  hasAutoPay?: boolean;
  hasPaperlessBill?: boolean;
  hasEmailAddress?: boolean;
}

export interface UtmParams {
  utm_term?: string;
  utm_content?: string;
  utm_plan?: string;
  utm_pp?: string;
}

export interface Resume {
  enabled?: boolean;
  sweetenerSelected?: boolean;
  serviceAddress?: Address;
  creditChecked?: boolean;
  acceptedResume?: boolean;
  viewedModal?: boolean;
  cartPage?: string;
  completed?: boolean;
  progressBannerSeen?: boolean;
}
export interface SingleForm {
  enabled?: boolean;
  activeStep?: number;
  contactCredit?: string;
  creditBypassAttempt?: number;
  installationOptions?: string;
  installation?: string;
  review?: boolean;
  payment?: string;
  dob?: string;
  ssnStatus?: string;
  progress?: number;
}
export interface AMEX {
  isAmexOffer?: boolean;
}

export interface ExpirationBanner {
  expirationTime?: string;
  expired?: boolean;
}

export interface SelfInstall {
  enabled?: boolean;
  selectedInstallationOption?: string;
}

export enum LiwPageReasons {
  LIW = 'liw',
  HOA_RESTRICTED = 'hoa_restricted',
}

// Redux
export enum ActionTypes {
  SET_TRACKING = 'tracking/set',
  SET_NOTIFICATIONS = 'notifications/set',
  SET_PAGE_LOADING = 'pageLoading/set',
  SET_CALL_IN_NUMBNER = 'callInNumber/set',
  REMOVE_ACP = 'acp/remove',
  REMOVE_QUOTE = 'quote/remove',
  SET_MONARCH_DECISION = 'monarch/set',
  SET_PREAMP_ACTION = 'preamp/set',
  COUNTDOWN_EXPIRATION = 'countdownExpiration/set',
  SET_C2F = 'c2f/set',
  SET_RESUME = 'resume/set',
  SET_AMEX = 'amex/set',
  SET_INSTALL_PAYMENT = 'installPayment/set',
  SET_FEE_CONSENT = 'feeConsent/set',
  SET_CARD_TYPE = 'cardType/set',
  SET_COMMERCIAL_CC_FEE = 'commercialCCFee/set',
  DELETE_ITEM = 'item/delete',
  SET_COOKIE_PASS = 'cookiePass/set',
  SET_SESSION_EXPIRED = 'sessionExpired/set',
  SET_HIDE_FRONTIER_LOGO = 'hideFrontierLogo/set',
  RESET_STATE = 'resetState',
  SET_EXPIRATION_BANNER = 'expirationBanner/set',
  REMOVE_EXPIRATION_BANNER = 'expirationBanner/remove',
  SET_SINGLE_FORM = 'singleForm/set',
  RESET_SINGLE_FORM = 'singleForm/reset',
  SET_HIDE_DEBUG = 'hideDebug/set',
  SET_INSTALLATION_OPTIONS = 'installationOptions/set',
  SET_AVAILABILITY = 'availability/set',
  CLEAR_AVAILABILITY = 'availability/clear',
  SET_DISCOUNT_OPTIONS = 'discountOptions/set',
  CLEAR_DISCOUNT_OPTIONS = 'discountOptions/clear',
  SET_LIW_PAGE_REASON = 'liwPageReason/set',
  SET_CUSTOM_OFFERS = 'customOffers/set',
  SET_BUYFLOW_REDIRECT_DELAY = 'buyflowRedirectDelay/set',
  SET_UTM_PARAMS = 'SET_UTM_PARAMS',
  SET_ISP = 'SET_ISP',
}

export interface TrackingAction {
  type: ActionTypes.SET_TRACKING;
  key: string;
  id: string;
}

export interface PageLoadingAction {
  type: ActionTypes.SET_PAGE_LOADING;
  loading: boolean;
}

export type Notification = {
  message: string;
  type?: string;
};

export type CallInNumberAction = {
  type: ActionTypes.SET_CALL_IN_NUMBNER;
  callInNumber: string;
};

export type DeleteStateItem = {
  type: ActionTypes.DELETE_ITEM;
};

export type RemoveAcpAction = {
  type: ActionTypes.REMOVE_ACP;
};

export type RemoveQuoteAction = {
  type: ActionTypes.REMOVE_QUOTE;
};

export type RemoveExpirationBannerAction = {
  type: ActionTypes.REMOVE_EXPIRATION_BANNER;
};

export interface NotificationsAction {
  type: ActionTypes.SET_NOTIFICATIONS;
  notifications: Notification[];
}

export interface MonarchDecisionPayload {
  [key: string]: Record<string, unknown> | string | string[] | number | boolean;
}
export interface MonarchDecisionAction {
  type: ActionTypes.SET_MONARCH_DECISION;
  payload: MonarchDecisionPayload;
}

export interface PreampAction {
  type: ActionTypes.SET_PREAMP_ACTION;
  key: string;
  value: Record<string, unknown> | string | number | boolean;
}
export interface CountdownExpiration {
  type: ActionTypes.COUNTDOWN_EXPIRATION;
  countdownExpiration: string;
}

export interface Copper2FiberAction {
  type: ActionTypes.SET_C2F;
  key: string;
  value: boolean | string;
}

export interface ResumeAction {
  type: ActionTypes.SET_RESUME;
  key: string;
  value: boolean | string;
}
export interface SingleFormAction {
  type: ActionTypes.SET_SINGLE_FORM;
  key: string;
  value: boolean | string | number;
}

export interface ResetSingleFormAction {
  type: ActionTypes.RESET_SINGLE_FORM;
}

export interface AMEXAction {
  type: ActionTypes.SET_AMEX;
  isAmexOffer: boolean;
}

export interface InstallPaymentAction {
  type: ActionTypes.SET_INSTALL_PAYMENT;
  singlePayment: boolean;
}

export interface CommercialFeeConsentAction {
  type: ActionTypes.SET_FEE_CONSENT;
  commercialFeeConsent: boolean;
}

export interface CommercialCCFeeAction {
  type: ActionTypes.SET_COMMERCIAL_CC_FEE;
  commercialCreditCardfee: number;
}

export interface CardTypeAction {
  type: ActionTypes.SET_CARD_TYPE;
  cardType: string;
}

export interface CookiePassAction {
  type: ActionTypes.SET_COOKIE_PASS;
  cookiePass: boolean;
}

export interface SessionExpiredAction {
  type: ActionTypes.SET_SESSION_EXPIRED;
  expired: boolean;
}

export interface HideFrontierLogoAction {
  type: ActionTypes.SET_HIDE_FRONTIER_LOGO;
  hide: boolean;
}

export interface ResetStateAction {
  type: ActionTypes.RESET_STATE;
}

export interface ExpirationBannerAction {
  type: ActionTypes.SET_EXPIRATION_BANNER;
  key: string;
  value: boolean | string;
}

export interface HideDebugAction {
  type: ActionTypes.SET_HIDE_DEBUG;
  value: boolean;
}

export interface SetInstallationOptionsAction {
  type: ActionTypes.SET_INSTALLATION_OPTIONS;
  key?: string;
  value: InstallationOptions | string | boolean;
}

export interface SetAvailabilityAction {
  type: ActionTypes.SET_AVAILABILITY;
  availability: AvailabilityResponse;
}

export interface ClearAvailabilityAction {
  type: ActionTypes.CLEAR_AVAILABILITY;
}

export interface SetDiscountOptionsAction {
  type: ActionTypes.SET_DISCOUNT_OPTIONS;
  value: DiscountOptions[];
}

export interface ClearDiscountOptionsAction {
  type: ActionTypes.CLEAR_DISCOUNT_OPTIONS;
}

export interface SetLiwPageReasonAction {
  type: ActionTypes.SET_LIW_PAGE_REASON;
  reason: LiwPageReasons;
}
export interface SetCustomOffersAction {
  type: ActionTypes.SET_CUSTOM_OFFERS;
  key?: string;
  value?: Record<string, unknown> | string | boolean | number;
}

export interface SetBuyflowRedirectDelayAction {
  type: ActionTypes.SET_BUYFLOW_REDIRECT_DELAY;
  value: number;
}

export interface SetUtmParamsAction {
  type: ActionTypes.SET_UTM_PARAMS;
  params: UtmParams;
}

export interface SetIspAction {
  type: ActionTypes.SET_ISP;
  isp: string;
}

export type Actions =
  | TrackingAction
  | PageLoadingAction
  | NotificationsAction
  | CallInNumberAction
  | RemoveAcpAction
  | RemoveQuoteAction
  | MonarchDecisionAction
  | PreampAction
  | CountdownExpiration
  | Copper2FiberAction
  | AMEXAction
  | InstallPaymentAction
  | CommercialFeeConsentAction
  | CardTypeAction
  | CommercialCCFeeAction
  | ResumeAction
  | CookiePassAction
  | SessionExpiredAction
  | HideFrontierLogoAction
  | ResetStateAction
  | ExpirationBannerAction
  | RemoveExpirationBannerAction
  | SingleFormAction
  | ResetSingleFormAction
  | HideDebugAction
  | SetInstallationOptionsAction
  | SetAvailabilityAction
  | ClearAvailabilityAction
  | SetDiscountOptionsAction
  | ClearDiscountOptionsAction
  | SetLiwPageReasonAction
  | SetCustomOffersAction
  | SetBuyflowRedirectDelayAction
  | SetUtmParamsAction
  | SetIspAction;
