/* eslint max-len: 0 */
import React, { SVGProps } from 'react';

const WholeHomeWifi = (props: SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      width="28"
      height="29"
      viewBox="0 0 28 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="icon icon--WholeHomeWifi"
      {...props}
    >
      <path
        d="M13.3046 2.98729C13.7314 2.72646 14.2683 2.72646 14.6951 2.98729L24.0284 8.691C24.4248 8.9332 24.6665 9.36421 24.6665 9.8287V23.6665H27.1998C27.2873 23.2357 27.3332 22.7898 27.3332 22.3331V9.8287C27.3332 8.43524 26.608 7.1422 25.419 6.41558L16.0856 0.711873C14.8052 -0.0706239 13.1945 -0.0706245 11.914 0.711872L2.5807 6.41558C1.39169 7.1422 0.666504 8.43524 0.666504 9.8287V22.3331C0.666504 22.7898 0.712413 23.2357 0.799867 23.6665H3.33317V9.8287C3.33317 9.36421 3.5749 8.9332 3.97124 8.69099L13.3046 2.98729Z"
        fill={props?.fill || 'white'}
      />
      <path
        d="M26.0003 26.3332H19.3332C19.3312 26.3332 19.3292 26.3332 19.3273 26.3331H9.33906L9.33317 26.3332H1.99937C3.21564 27.9524 5.15209 28.9998 7.33317 28.9998H20.6665C22.8476 28.9998 24.784 27.9524 26.0003 26.3332Z"
        fill={props?.fill || 'white'}
      />
      <path
        d="M4.99268 11.9932C7.01136 10.1347 9.70651 8.99981 12.6667 8.99981H15.3334C18.2936 8.99981 20.9888 10.1347 23.0074 11.9932L21.4433 14.1866C19.8764 12.629 17.7173 11.6665 15.3334 11.6665H12.6667C10.2828 11.6665 8.12374 12.629 6.55685 14.1866L4.99268 11.9932Z"
        fill={props?.fill || 'white'}
      />
      <path
        d="M19.7012 16.6294C18.4789 15.2226 16.6767 14.3331 14.6667 14.3331H13.3334C11.3235 14.3331 9.52126 15.2226 8.29896 16.6294L9.92358 18.9075C10.6274 17.763 11.8913 16.9998 13.3334 16.9998H14.6667C16.1089 16.9998 17.3728 17.763 18.0765 18.9075L19.7012 16.6294Z"
        fill={props?.fill || 'white'}
      />
      <path
        d="M11.9998 21.6665C11.9998 20.5619 12.8953 19.6665 13.9998 19.6665C15.1044 19.6665 15.9998 20.5619 15.9998 21.6665C15.9998 22.771 15.1044 23.6665 13.9998 23.6665C12.8953 23.6665 11.9998 22.771 11.9998 21.6665Z"
        fill={props?.fill || 'white'}
      />
    </svg>
  );
};

WholeHomeWifi.displayName = 'WholeHomeWifi';

export default WholeHomeWifi;
