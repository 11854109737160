import { QuizQuestion } from '~/types/quiz';
import { WIFI_SECURITY, WHOLE_HOME_WIFI, MY_PREMIUM_TECH_PRO, YTTV_BASIC, UNLIMITED_VOICE, TOTAL_SHIELD, IDENTITY_PROTECTION, WIFI_SECURITY_PLUS, UNBREAKABLE_WIFI, WHOLE_HOME_WIFI_PLUS } from '~/constants/entityIds';

export const questions: QuizQuestion[] = [
  {
    id: 0,
    question: 'How many people use the internet in your home?',
    answerChoices: [
      {
        id: 0,
        answer: 'Just me',
        value: 0,
        userCount: 1,
      },
      {
        id: 1,
        answer: 'Me and one other person',
        value: 1.35,
        userCount: 2,
      },
      {
        id: 2,
        answer: 'Three people',
        value: 2.1,
        userCount: 3,
      },
      {
        id: 3,
        answer: 'Four or more people',
        value: 3.5,
        userCount: 4,
      },
    ],
    checkbox: {
      id: 0,
      answer: 'I frequently have guests who use my internet',
      value: 0.25,
    },
    allowMultiple: false,
    isVAS: false,
    weight: 0.425,
    additive: 0,
  },
  {
    id: 1,
    question: 'How many devices are usually connected to your internet?',
    subtitle: 'Count all cell phones, smart TVs, game consoles and smart home devices.',
    answerChoices: [
      {
        id: 0,
        answer: '1-5 devices',
        value: 0.25,
      },
      {
        id: 1,
        answer: '6-10 devices',
        value: 0.5,
      },
      {
        id: 2,
        answer: '11-15 devices',
        value: 0.75,
      },
      {
        id: 3,
        answer: '16-20 devices',
        value: 1,
      },
      {
        id: 4,
        answer: '20+ devices',
        value: 1.25,
      },
    ],
    allowMultiple: false,
    isVAS: false,
    weight: 0.15,
    additive: 0,
  },
  {
    id: 2,
    question: 'What do you usually use your internet for?',
    subtitle: 'Select all that apply.',
    answerChoices: [
      {
        id: 0,
        answer: 'Web browsing',
        value: 1,
      },
      {
        id: 1,
        answer: 'Streaming or watching TV, like YouTube TV',
        value: 1,
      },
      {
        id: 2,
        answer: 'Remote work or online school',
        value: 2,
      },
      {
        id: 3,
        answer: 'Running smart home devices',
        value: 2,
      },
      {
        id: 4,
        answer: 'Online gaming',
        value: 2,
      },
      {
        id: 5,
        answer: 'Content creation, like going live on social media',
        value: 3,
      },
    ],
    allowMultiple: true,
    isVAS: false,
    weight: 0.425,
    additive: 0.2,
  },
  {
    id: 3,
    question: 'What other activities are most common in your home?',
    subtitle: 'Select all that apply.',
    answerChoices: [
      {
        id: 0,
        answer: 'Watching TV with roommates or family',
        addOn: {
          default: ['youtube-tv'],
        },
      },
      {
        id: 1,
        answer: 'Kids using the internet',
        addOn: {
          default: ['eero-secure'],
          '5000': ['total-shield'],
        },
      },
      {
        id: 2,
        answer: 'Talking on my home phone line',
        addOn: {
          default: ['home-phone-line'],
        },
      },
      {
        id: 3,
        answer: 'Using Wi-Fi in multiple rooms',
        addOn: {
          default: ['whole-home-wifi'],
        },
      },
      {
        id: 4,
        answer: 'Trying out new tech',
        addOn: {
          default: ['my-premium-tech-pro'],
        },
      },
      {
        id: 5,
        answer: 'Using online security software',
        addOn: {
          default: ['total-shield', 'identity-protection'],
        },
      },
    ],
    allowMultiple: true,
    isVAS: true,
    weight: 1,
    additive: 0,
  },
  {
    id: 4,
    question: 'When picking an internet plan for my home, fast speeds are...',
    answerChoices: [
      {
        id: 0,
        answer: 'Not really important to me',
        value: -0.5,
      },
      {
        id: 1,
        answer: 'Nice to have, but my budget is more important',
        value: 0,
      },
      {
        id: 2,
        answer: 'Necessary for my daily use',
        value: 0.5,
      },
      {
        id: 3,
        answer: 'Great, I want the fastest speeds possible',
        value: 1,
      },
    ],
    allowMultiple: false,
    isVAS: false,
    weight: 1,
    additive: 0,
  },
];

export const planMap = {
  fiber500: {
    min: -100,
    max: 1.25,
    productId: 'c75fb0df-6d48-4a06-bb10-3ee47a8d3d28',
  },
  fiber1Gig: {
    min: 1.25,
    max: 2.5,
    productId: '2b09989e-1696-4cc2-b13c-7e0950b74588',
  },
  fiber2Gig: {
    min: 2.5,
    max: 3.75,
    productId: '67e4d13f-65f8-485b-8ebc-9c922e72c1a2',
  },
  fiber5Gig: {
    min: 3.75,
    max: 100,
    productId: '4a7c0a86-9b8d-4b5f-b60f-10849155f5c0',
  },
};

// Hierarchal ranking of add ons to recommend
export const addOnMap = {
  'whole-home-wifi': {
    rank: 0,
    productId: WHOLE_HOME_WIFI,
  },
  'wifi-security-plus': {
    rank: 1,
    productId: WIFI_SECURITY_PLUS,
  },
  'unbreakable-wifi': {
    rank: 2,
    productId: UNBREAKABLE_WIFI,
  },
  'whole-home-wifi-plus': {
    rank: 10,
    productId: WHOLE_HOME_WIFI_PLUS,
  },
  'youtube-tv': {
    rank: 3,
    productId: YTTV_BASIC,
  },
  'eero-secure': {
    rank: 4,
    productId: WIFI_SECURITY,
  },
  'identity-protection': {
    rank: 5,
    productId: IDENTITY_PROTECTION,
  },
  'my-premium-tech-pro': {
    rank: 6,
    productId: MY_PREMIUM_TECH_PRO,
  },
  'home-phone-line': {
    rank: 7,
    productId: UNLIMITED_VOICE,
  },
  'total-shield': {
    rank: 8,
    productId: TOTAL_SHIELD,
  },
};

export const productRecFeatures = {
  // Fiber 500
  'c75fb0df-6d48-4a06-bb10-3ee47a8d3d28': {
    bestForCopy: 'Homes with a few users and multiple devices',
    included: [
      {
        text: 'Amazon eero router',
        eeroModal: true,
        tooltip:
          'eero max. speeds are derived from IEEE specifications. Coverage varies based on normal use and building conditions. Actual range and performance can vary. See https://eero.com/legal for more details. ©2024 Amazon.com, Inc. or its affiliates. eero and all related marks are trademarks of Amazon.com, Inc. or its affiliates. Amazon.com c/o eero LLC, 660 3rd St. San Francisco, CA.',
      },
      {
        text: 'Whole-Home Wi-Fi',
        tooltip: 'Whole-Home Wi-Fi billed at $0 first 3 months then $10 per month.',
      },
      {
        text: (
          <>
            Upload speeds 5x faster than cable
          </>
        ),
        tooltip:
          'Based on comparison of Frontier Fiber 500 upload speed up to 500 Mbps versus advertised cable upload speeds for Xfinity Blast! plan of 10 Mbps, Spectrum Internet Ultra plan of 20 Mbps, and Optimum 500 plan of 20 Mbps (excludes Optimum Fiber). As of 10/9/23.',
      },
    ],
    whyPicked: [
      {
        default: 'Great for the basics of internet usage',
        '1': 'Great for all the basics of internet usage and then some',
        '2': 'Great for the basics of internet usage',
      },
      {
        default: 'Good for a few users',
        '1': 'Good for one or two users ',
        '2': 'Good for one or two users',
      },
      {
        default: 'Everyone can browse online and stream videos at the same time',
        '1': 'You can browse, stream and video call on multiple devices',
        '2': 'You can both browse online and stream videos at the same time',
      },
    ],
  },
  // Fiber 1 Gig
  '2b09989e-1696-4cc2-b13c-7e0950b74588': {
    bestForCopy: 'Homes with several users and multiple devices',
    included: [
      {
        text: 'Amazon eero router',
        eeroModal: true,
        tooltip:
          'eero max. speeds are derived from IEEE specifications. Coverage varies based on normal use and building conditions. Actual range and performance can vary. See https://eero.com/legal for more details. ©2024 Amazon.com, Inc. or its affiliates. eero and all related marks are trademarks of Amazon.com, Inc. or its affiliates. Amazon.com c/o eero LLC, 660 3rd St. San Francisco, CA.',
      },
      {
        text: 'Whole-Home Wi-Fi',
        tooltip: 'Whole-Home Wi-Fi billed at $0 first 3 months then $10 per month.',
      },
      {
        text: (
          <>
            Upload speeds 10x faster than cable
          </>
        ),
        tooltip:
          'Based on comparison of Frontier Fiber 1 Gig upload speed up to 880 Mbps versus advertised cable upload speeds for Xfinity Gigabit plan of 35 Mbps, Spectrum Internet Gig plan of 35 Mbps, and Optimum 1 Gig Internet plan of 35 Mbps (excludes Optimum Fiber). As of 10/9/23.',
      },
    ],
    whyPicked: [
      {
        default: 'Ideal for several devices and heavy internet usage',
        '1': 'Ideal for heavy internet usage',
        '2': 'Ideal for several devices and heavy internet usage',
      },
      {
        default: 'A fast, affordable option that keeps you connected',
        '1': 'Good for multiple devices',
        '2': 'A fast, affordable option that keeps you connected',
      },
      {
        default: 'Supports everyone’s online activities with virtually no lag',
        '1': "You'll have a fast, affordable connection with virtually no lag",
        '2': 'Supports both of your online activities with virtually no lag',
      },
    ],
  },
  // Fiber 2 Gig
  '67e4d13f-65f8-485b-8ebc-9c922e72c1a2': {
    bestForCopy: 'Homes with multiple users and dozens of devices',
    included: [
      {
        text: (
          <>
            $200 Visa<sup>&reg;</sup> Reward Card
          </>
        ),
        tooltip:
          'Not redeemable for cash. 12-month pro-rated early termination fee will be applied by Frontier based on card amount if the optional Visa offer is selected. Must maintain service and pricing offer without change for 90 days after install and pay first bill in full. Must redeem within 75 days of notification. Card delivered 30 days after redemption and expires 6 months after issuance. Visit RewardCenter.Frontier.com for Cardholder Agreement. The Frontier Visa Reward Card is issued by The Bancorp Bank pursuant to a license from Visa U.S.A. Inc. and can be used everywhere Visa debit cards are accepted. No cash access. The Bancorp Bank; Member FDIC. The Bancorp Bank does not endorse or sponsor and is not affiliated in any way with any product or service offered by Frontier Communications.',
        isRewards: true,
      },
      {
        text: 'Amazon eero router',
        eeroModal: true,
        tooltip:
          'eero max. speeds are derived from IEEE specifications. Coverage varies based on normal use and building conditions. Actual range and performance can vary. See https://eero.com/legal for more details. ©2024 Amazon.com, Inc. or its affiliates. eero and all related marks are trademarks of Amazon.com, Inc. or its affiliates. Amazon.com c/o eero LLC, 660 3rd St. San Francisco, CA.',
      },
      {
        text: 'Whole-Home Wi-Fi',
        tooltip: 'Whole-Home Wi-Fi billed at $0 first 3 months then $10 per month.',
      },
      {
        text: (
          <>
            Upload speeds up to 10x faster than cable
          </>
        ),
        tooltip:
          'Based on comparison of Frontier Fiber 2 Gig upload speed up to 2000 Mbps versus advertised cable upload speeds for Xfinity 2 Gig plan of 100 Mbps, Spectrum Internet Gig plan of 35 Mbps, and Optimum 1 Gig Internet plan of 35 Mbps (excludes Optimum Fiber). As of 10/9/23.',
      },
    ],
    whyPicked: [
      {
        default: 'Supercharged speeds for a busy network',
        '2': 'Supercharged speeds for a busy online lifestyle',
      },
      {
        default: 'Good for many users and many smart devices',
        '2': 'Good for many users and smart home devices',
      },
      {
        default: 'Everyone can game, stream high quality videos, work remotely and more',
        '2': 'Both of you can game, stream high quality videos, work remotely and more',
      },
    ],
  },
  // Fiber 5 Gig
  '4a7c0a86-9b8d-4b5f-b60f-10849155f5c0': {
    bestForCopy: 'Large smart homes with multiple users and hundreds of devices',
    included: [
      {
        text: 'Amazon eero router',
        eeroModal: true,
        tooltip:
          'eero max. speeds are derived from IEEE specifications. Coverage varies based on normal use and building conditions. Actual range and performance can vary. See https://eero.com/legal for more details. ©2024 Amazon.com, Inc. or its affiliates. eero and all related marks are trademarks of Amazon.com, Inc. or its affiliates. Amazon.com c/o eero LLC, 660 3rd St. San Francisco, CA.',
      },
      {
        text: 'Whole-Home Wi-Fi',
        tooltip: 'Whole-Home Wi-Fi billed at $0 first 3 months then $10 per month.',
      },
      {
        text: (
          <>
            Upload speeds up to 25x faster than cable
          </>
        ),
        tooltip:
          'Based on comparison of Frontier Fiber 5 Gig upload speed up to 5000 Mbps versus advertised cable upload speeds for Xfinity 2 Gig plan of 100 Mbps, Spectrum Internet Gig plan of 35 Mbps, and Optimum 1 Gig Internet plan of 35 Mbps (excludes Optimum Fiber). As of 10/9/23.',
      },
    ],
    whyPicked: [
      {
        default: 'Our most powerful speeds with Wi-Fi 7',
      },
      {
        default: 'Good for hundreds of devices and intense internet usage',
      },
      {
        default: 'Get the ultimate performance in content creation, VR and more',
      },
    ],
  },
  // Copper (multiple product Ids)
  copper: {
    included: [
      {
        text: 'No long-term contracts',
      },
      {
        text: 'Watch entertainment in 4K',
      },
      {
        text: 'Save $10/mo with Auto Pay',
      },
      {
        text: 'Amazon eero 6 Wi-Fi router included',
        tooltip:
          'eero max. speeds are derived from IEEE specifications. Coverage varies based on normal use and building conditions. Actual range and performance can vary. See https://eero.com/legal for more details. ©2024 Amazon.com, Inc. or its affiliates. eero and all related marks are trademarks of Amazon.com, Inc. or its affiliates. Amazon.com c/o eero LLC, 660 3rd St. San Francisco, CA.',
      },
    ],
    whyPicked: [
      {
        default: 'Great for basic internet usage',
      },
      {
        default: 'No data caps or overage charges',
      },
      {
        default: 'Use the internet to surf, stream and email',
      },
    ],
  },
  // Fiber 30
  '80e83f56-69b9-49af-8fe4-290bf9b229c1': {
    included: [
      {
        text: 'No long-term contracts',
      },
      {
        text: 'Check Email, browse the internet, stream video and game',
      },
      {
        text: 'Save $10/mo with Auto Pay',
      },
      {
        text: 'Amazon eero Wi-Fi router included',
        tooltip:
          'eero max. speeds are derived from IEEE specifications. Coverage varies based on normal use and building conditions. Actual range and performance can vary. See https://eero.com/legal for more details. ©2024 Amazon.com, Inc. or its affiliates. eero and all related marks are trademarks of Amazon.com, Inc. or its affiliates. Amazon.com c/o eero LLC, 660 3rd St. San Francisco, CA.',
      },
    ],
    whyPicked: [
      {
        default: 'Great for all the essentials of internet usage and then some',
      },
      {
        default: 'Good for one user or small families',
      },
      {
        default: 'You’ll have a reliable, affordable connection',
      },
    ],
  },
  // Fiber 200
  'd03c8fa0-4464-4ee7-a979-0700e2f5d290': {
    included: [
      {
        text: 'No long-term contracts',
      },
      {
        text: 'Check Email, browse the internet, stream video and game',
      },
      {
        text: 'Save $10/mo with Auto Pay',
      },
      {
        text: 'Amazon eero Wi-Fi router included',
        tooltip:
          'eero max. speeds are derived from IEEE specifications. Coverage varies based on normal use and building conditions. Actual range and performance can vary. See https://eero.com/legal for more details. ©2024 Amazon.com, Inc. or its affiliates. eero and all related marks are trademarks of Amazon.com, Inc. or its affiliates. Amazon.com c/o eero LLC, 660 3rd St. San Francisco, CA.',
      },
    ],
    whyPicked: [
      {
        default: 'Great for all the essentials of internet usage and then some',
      },
      {
        default: 'Good for one user or small families',
      },
      {
        default: 'You’ll have a reliable, affordable connection',
      },
    ],
  },
};
