import React, { ButtonHTMLAttributes, FC } from 'react';
import ButtonStyled from './ButtonStyled';
import SmallCircleLoader from './components/SmallCircleLoader';
interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  children?: JSX.Element[] | JSX.Element | string;
  loading?: boolean;
  large?: boolean;
  primary?: boolean;
  secondary?: boolean;
  as?: React.ElementType;
  href?: string;
  buttonConfig?: {
    [key: string]: string | boolean;
  };
  enabled?: boolean;
}

const Button: FC<Props> = ({ children, className, loading, disabled, secondary, buttonConfig, ...props }) => {
  return (
    <ButtonStyled
      className={className}
      disabled={disabled}
      $loading={loading}
      secondary={secondary}
      buttonConfig={buttonConfig}
      {...props}
    >
      {loading ? <SmallCircleLoader /> : <span>{children}</span>}
    </ButtonStyled>
  );
};

Button.defaultProps = {
  children: '',
  className: '',
  loading: false,
  disabled: false,
};

Button.displayName = 'Button';

export default Button;
