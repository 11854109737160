/* eslint max-len: 0 */
import React, { SVGProps } from 'react';

const CloseThin = (props: SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="icon icon--fill-close-thin"
      {...props}
    >
      <path
        d="M11.9142 14.7427L21 23.8284L23.8284 21L14.7427 11.9142L23.8285 2.82843L21.0001 0L11.9142 9.08582L2.82843 0L0 2.82843L9.08582 11.9142L5.79357e-05 21L2.82849 23.8284L11.9142 14.7427Z"
        fill={props?.fill || '#C4C5C9'}
      />
    </svg>
  );
};

CloseThin.displayName = 'CloseThin';

export default CloseThin;
