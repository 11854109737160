/* eslint max-len: 0 */
import React, { SVGProps } from 'react';

const Headphones = (props: SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      focusable="false"
      data-icon="headset"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 28 28"
      className="icon icon--fill-headset"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 0.666687C7.21349 0.666687 3.33329 4.54689 3.33329 9.33335V12.7334C1.8118 13.0422 0.666626 14.3874 0.666626 16V18.6667C0.666626 20.2793 1.8118 21.6245 3.33329 21.9333V22.6667C3.33329 25.244 5.42263 27.3334 7.99996 27.3334H15.3333V24.6667H7.99996C6.89539 24.6667 5.99996 23.7713 5.99996 22.6667V21.9333C7.52145 21.6245 8.66663 20.2793 8.66663 18.6667V16C8.66663 14.3874 7.52145 13.0422 5.99996 12.7334V9.33335C5.99996 6.01965 8.68625 3.33335 12 3.33335H16C19.3137 3.33335 22 6.01965 22 9.33335V12.7334C20.4785 13.0422 19.3333 14.3874 19.3333 16V18.6667C19.3333 20.5076 20.8257 22 22.6666 22H24C25.8409 22 27.3333 20.5076 27.3333 18.6667V16C27.3333 14.3874 26.1881 13.0422 24.6666 12.7334V9.33335C24.6666 4.54689 20.7864 0.666687 16 0.666687H12ZM3.99996 15.3334H5.33329C5.70148 15.3334 5.99996 15.6318 5.99996 16V18.6667C5.99996 19.0349 5.70148 19.3334 5.33329 19.3334H3.99996C3.63177 19.3334 3.33329 19.0349 3.33329 18.6667V16C3.33329 15.6318 3.63177 15.3334 3.99996 15.3334ZM24 15.3334H22.6666C22.2984 15.3334 22 15.6318 22 16V18.6667C22 19.0349 22.2984 19.3334 22.6666 19.3334H24C24.3681 19.3334 24.6666 19.0349 24.6666 18.6667V16C24.6666 15.6318 24.3681 15.3334 24 15.3334Z"
        fill={props?.fill || "#141928"}
      />
      <path
        d="M14.6339 7.28472C14.4343 6.67051 13.5654 6.67051 13.3658 7.28472L12.5031 9.93991H9.71125C9.06544 9.93991 8.79692 10.7663 9.3194 11.1459L11.578 12.7869L10.7153 15.4421C10.5157 16.0563 11.2187 16.5671 11.7412 16.1875L13.9998 14.5465L16.2585 16.1875C16.781 16.5671 17.4839 16.0563 17.2844 15.4421L16.4216 12.7869L18.6803 11.1459C19.2028 10.7663 18.9342 9.93991 18.2884 9.93991H15.4966L14.6339 7.28472Z"
        fill={props?.fill || "#141928"}
      />
    </svg>
  );
};

Headphones.displayName = 'Headphones';

export default Headphones;
