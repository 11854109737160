/* eslint max-len: 0 */
import React, { SVGProps } from 'react';

const Ligntning = (props: SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" className="icon icon--ligntning" {...props}>
      <path d="M26.9743 14.7711C26.9364 14.6108 26.8596 14.4624 26.7505 14.339C26.6415 14.2156 26.5037 14.121 26.3493 14.0636L19.148 11.3624L20.9805 2.19612C21.022 1.98315 20.9931 1.76246 20.8982 1.56735C20.8033 1.37224 20.6474 1.21331 20.4542 1.11453C20.2611 1.01574 20.041 0.982477 19.8272 1.01974C19.6135 1.05701 19.4176 1.16279 19.2693 1.32112L5.26928 16.3211C5.15557 16.4409 5.07332 16.5871 5.02986 16.7464C4.9864 16.9058 4.9831 17.0734 5.02024 17.2344C5.05738 17.3953 5.13381 17.5446 5.24271 17.6688C5.3516 17.793 5.48957 17.8883 5.64428 17.9461L12.848 20.6474L11.0205 29.8036C10.979 30.0166 11.0079 30.2373 11.1029 30.4324C11.1978 30.6275 11.3536 30.7864 11.5468 30.8852C11.74 30.984 11.9601 31.0173 12.1738 30.98C12.3876 30.9427 12.5834 30.837 12.7318 30.6786L26.7318 15.6786C26.8434 15.5588 26.9239 15.4134 26.9662 15.2551C27.0085 15.0969 27.0113 14.9307 26.9743 14.7711ZM13.6718 26.7499L14.9805 20.2024C15.0274 19.9702 14.9903 19.7289 14.8758 19.5215C14.7613 19.3141 14.577 19.154 14.3555 19.0699L7.75053 16.5886L18.328 5.25612L17.0205 11.8036C16.9737 12.0358 17.0108 12.2771 17.1253 12.4845C17.2397 12.6919 17.4241 12.852 17.6455 12.9361L24.2455 15.4111L13.6718 26.7499Z" fill="#FAFAFA"/>
    </svg>
  );
};

Ligntning.displayName = 'Ligntning';

export default Ligntning;
