/* eslint max-len: 0 */
import React, { SVGProps } from 'react';

const ChevronLeftBold = (props: SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      width="6"
      height="9"
      viewBox="0 0 6 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="icon icon--fill-chevron-left-bold"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.81554 8.9834L5.61133 8.18761L1.9296 4.50588L5.61133 0.824157L4.81554 0.0283699L0.338028 4.50588L4.81554 8.9834Z"
        fill="#2D3548"
      />
    </svg>
  );
};

ChevronLeftBold.displayName = 'ChevronLeftBold';

export default ChevronLeftBold;
