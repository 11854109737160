import styled, { css } from 'styled-components';
import convertHexToRgba from '~/helpers/convertHexToRgba';

export default styled.button.attrs({
  className: 'btn',
  type: 'button',
})`
  ${({ theme, $fixed, $hide, $loading, $small, secondary, buttonConfig }) => {
    const { media, colors } = theme;
    const {
      backgroundColor,
      borderColor,
      textColor,
      hoverBackgroundColor,
      hoverBorderColor,
      hoverTextColor,
      activeTextColor,
      activeBackgroundColor,
      activeBorderColor,
    } = buttonConfig || {};

    return css`
      background-color: ${secondary
        ? colors.primary[backgroundColor || 'white']
        : colors.primary[backgroundColor || 'red']};
      border: 1px solid ${secondary ? colors.primary[borderColor || 'darkBlue'] : colors.primary[borderColor || 'red']};
      color: ${secondary ? colors.primary[textColor || 'darkBlue'] : colors.primary[textColor || 'white']};
      cursor: pointer;
      display: inline-block;
      font-weight: 700;
      font-size: 1rem;
      height: ${$small ? '40px' : 'auto'};
      text-decoration: none;
      text-transform: none;
      margin: 0;
      border-radius: 32px;
      padding: ${$small ? '0 24px' : '14px'};
      width: ${$small ? '160px' : '255px'};
      transition: background-color 0.2s, color 0.2s;

      &:disabled {
        background-color: ${convertHexToRgba(colors.primary[backgroundColor || 'red'], 0.5)};
        border-color: transparent;
        color: ${colors.primary.white};
        cursor: no-drop;

        &:hover {
          background-color: ${convertHexToRgba(colors.primary[backgroundColor || 'red'], 0.5)};
          color: ${colors.primary.white};
        }
      }

      ${!$loading &&
      css`
        &:hover {
          background-color: ${colors.primary[hoverBackgroundColor || 'white']};
          border: 1px solid
            ${secondary ? colors.primary[hoverBorderColor || 'darkBlue'] : colors.primary[hoverBorderColor || 'red']};
          color: ${secondary ? colors.primary[hoverTextColor || 'darkBlue'] : colors.primary[hoverTextColor || 'white']};
          text-decoration: underline;
        }
      `}

      &.active {
        background-color: ${colors.primary[activeBackgroundColor || 'white']};
        border: 1px solid
          ${secondary ? colors.primary[activeBorderColor || 'darkBlue'] : colors.primary[activeBorderColor || 'red']};
        color: ${secondary ? colors.primary[activeTextColor || 'darkBlue'] : colors.primary[activeTextColor || 'red']};
        text-decoration: none;
      }

      &.icon span {
        display: flex;
        justify-content: center;
        align-items: center;
        white-space: nowrap;

        svg {
          width: 1.125rem;
          margin-right: 0.5rem;
          transform: translateY(-2px);
        }
      }

      ${$hide &&
      css`
        display: none !important;
      `}

      ${$loading &&
      css`
        cursor: not-allowed;
        align-items: center;
        display: flex;
        justify-content: center;
      `}

      ${media.mobile.down} {
        ${!$fixed &&
        css`
          width: 100%;
          max-width: 100%;
        `}
      }
    `;
  }};
`;
