import React, { useContext } from 'react';
import dynamic from 'next/dynamic';
import { useSelector } from 'react-redux';
import { FuelContext } from '~/providers/FuelProvider';
import { PAGE_ROUTES } from '~/constants/pages';
import { ReduxState } from '~/types/redux';
import { MonarchSlot, useMonarchSlot } from '@redventures/cohesion-utils-react';

const Default = dynamic(() => import('./components/Default'));
const AddressCheck = dynamic(() => import('./components/AddressCheck'));
const CreditCheck = dynamic(() => import('./components/CreditCheck'));
const Checkout = dynamic(() => import('./components/Checkout'));
const EditableLoader = dynamic(() => import('./components/EditableLoader'));
import { cartConfigEditableLoaderDefaultValue } from '../../../monarch/Config | Editable Loader/default';
import { CartConfigEditableLoader } from '~/types/editable-loader';

const Loader = (): JSX.Element => {
  const metadata = useMonarchSlot<CartConfigEditableLoader>('cartConfigEditableLoader', {
    default: cartConfigEditableLoaderDefaultValue,
  })?.data;

  const { router } = useContext(FuelContext);
  const { pathname: currentPage } = router;
  // Monarch does not allow slashes in custom Ids so we replace them with underscores
  const formattedCurrentPage = currentPage.replace('/', '_');
  const { pageLoading, unservRedirect } = useSelector((state: ReduxState) => state);

  const addressCheck = formattedCurrentPage === PAGE_ROUTES.ADDRESS_CHECK.replace('/', '_');
  const addressCheckAi = formattedCurrentPage === PAGE_ROUTES.CUSTOMIZE.replace('/', '_');
  const creditCheckPage = formattedCurrentPage === PAGE_ROUTES.CONTACT_CREDIT.replace('/', '_');
  const productSelectionStep = formattedCurrentPage === PAGE_ROUTES.PLAN_PACKAGE.replace('/', '_');
  const fiber = formattedCurrentPage === PAGE_ROUTES.BUY_FIBER.replace('/', '_');
  const resume = formattedCurrentPage === PAGE_ROUTES.RESUME.replace('/', '_');
  const checkout = formattedCurrentPage === PAGE_ROUTES.CHECKOUT.replace('/', '_');
  const myCustomQuote = formattedCurrentPage === PAGE_ROUTES.MY_CUSTOM_QUOTE.replace('/', '_');

  const loadingText = (() => {
    if (productSelectionStep) return 'Just a moment, we’re getting your plan ready for activation.';
    if (creditCheckPage) return 'This step may take up to 2 minutes.';
    if (fiber) return 'Searching for the best fiber upgrades.';
    if (resume) return 'Loading your previous selections.';
    return 'Just a moment...';
  })();
  const loadingTextHeader = creditCheckPage ? 'Verifying your identity' : '';

  // Move this check outside of the Preamp placement so we don't get
  // a flash of the confirmation page before redirecting to Frontier's page
  if (currentPage.includes('confirmation')) {
    return <Default currentPage={currentPage} loadingText={loadingText} loadingTextHeader={loadingTextHeader} />;
  }

  const unservRedirectEnabled = metadata?.unservRedirectEnabled;

  // if there is an unserv provider
  // - check if the unservRedirectEnabled coming from the metadata is true
  const disableLoader =
    !pageLoading ||
    (!unservRedirect?.provider && addressCheckAi) ||
    (!unservRedirect?.provider && myCustomQuote) ||
    (unservRedirect?.provider && !unservRedirectEnabled);

  if (disableLoader) {
    return null;
  }

  const unservRedirectProps = Boolean(unservRedirectEnabled && unservRedirect);
  const currentPageConfig = metadata?.[formattedCurrentPage] as CartConfigEditableLoader;

  if (unservRedirectProps) {
    return (
      <EditableLoader
        metadata={metadata}
        unservRedirectProps={unservRedirectProps}
        currentPage={formattedCurrentPage}
      />
    );
  }

  // show the editable loader for serviceable addresses when enabled for the current page
  if (!unservRedirectProps && currentPageConfig?.enabled && currentPage !== '/') {
    return (
      <EditableLoader
        metadata={metadata}
        unservRedirectProps={unservRedirectProps}
        currentPage={formattedCurrentPage}
      />
    );
  }

  if (addressCheck || fiber) {
    return <AddressCheck />;
  }

  if (creditCheckPage) {
    return <CreditCheck />;
  }

  if (checkout) {
    return <Checkout />;
  }

  return (
    <MonarchSlot slotId="cartConfigEditableLoader">
      <Default currentPage={formattedCurrentPage} loadingText={loadingText} loadingTextHeader={loadingTextHeader} />
    </MonarchSlot>
  );
};

Loader.displayName = 'Loader';

export default Loader;
