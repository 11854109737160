import { CohesionProvider } from '@redventures/cohesion-utils-react';
import { ComponentType } from 'react';

export const withCohesion = ({
  App,
  components,
}: {
  App: ComponentType;
  components: Record<string, ComponentType>;
}): ComponentType => {
  const CohesionProviderHOC = (props) => (
    <CohesionProvider components={components}>
      <App {...props} />
    </CohesionProvider>
  );

  CohesionProviderHOC.displayName = 'CohesionProviderHOC';
  return CohesionProviderHOC;
};
