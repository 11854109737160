/* eslint max-len: 0 */
import React, { SVGProps } from 'react';

const Locks = (props: SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="icon icon--locks"
      {...props}
    >
      <path
        d="M4.33333 10.6667C3.96514 10.6667 3.66667 10.9651 3.66667 11.3333V12C3.66667 12.3682 3.96514 12.6667 4.33333 12.6667H5C5.36819 12.6667 5.66667 12.3682 5.66667 12V11.3333C5.66667 10.9651 5.36819 10.6667 5 10.6667H4.33333Z"
        fill="#FF0037"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 0C9.34315 0 8 1.34315 8 3V3.35632H9.33333V3C9.33333 2.07953 10.0795 1.33333 11 1.33333H11.6667C12.5871 1.33333 13.3333 2.07953 13.3333 3V4H9.33333C8.63434 4 7.99816 4.26894 7.52263 4.70898C6.98857 3.8813 6.05828 3.33333 5 3.33333H4.33333C2.67648 3.33333 1.33333 4.67648 1.33333 6.33333V6.68966H2.66667V6.33333C2.66667 5.41286 3.41286 4.66667 4.33333 4.66667H5C5.92048 4.66667 6.66667 5.41286 6.66667 6.33333V7.33333H2.66667C1.19391 7.33333 0 8.52724 0 10V13.3333C0 14.8061 1.19391 16 2.66667 16H6.66667C8.13943 16 9.33333 14.8061 9.33333 13.3333V12.6667H13.3333C14.8061 12.6667 16 11.4728 16 10V6.66667C16 5.67962 15.4637 4.81783 14.6667 4.35675V3C14.6667 1.34315 13.3235 0 11.6667 0H11ZM9.33333 10C9.33333 9.01296 8.79707 8.15117 8 7.69009V6.66667C8 5.93029 8.59695 5.33333 9.33333 5.33333H13.3333C14.0697 5.33333 14.6667 5.93029 14.6667 6.66667V10C14.6667 10.7364 14.0697 11.3333 13.3333 11.3333H9.33333V10ZM2.66667 8.66667H6.66667C7.40305 8.66667 8 9.26362 8 10V13.3333C8 14.0697 7.40305 14.6667 6.66667 14.6667H2.66667C1.93029 14.6667 1.33333 14.0697 1.33333 13.3333V10C1.33333 9.26362 1.93029 8.66667 2.66667 8.66667Z"
        fill="#FF0037"
      />
      <path
        d="M11 7.33333C10.6318 7.33333 10.3333 7.63181 10.3333 8V8.66667C10.3333 9.03486 10.6318 9.33333 11 9.33333H11.6667C12.0349 9.33333 12.3333 9.03486 12.3333 8.66667V8C12.3333 7.63181 12.0349 7.33333 11.6667 7.33333H11Z"
        fill="#FF0037"
      />
    </svg>
  );
};

Locks.displayName = 'Locks';

export default Locks;
