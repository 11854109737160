import React, { FC } from 'react';
import { ProgressStyled } from './ProgressStyled';
import Check from '../Icons/fill/Check';
import breadcrumbs from '~/constants/breadcrumbs';

interface Props {
  route: string;
  salesJourney?: string;
}

const Progress: FC<Props> = ({ route, salesJourney = 'selfInstallSalesJourney' }) => {
  const totalBreadcrumbs = breadcrumbs.length;
  let currentIndex = breadcrumbs.map(({ route }) => route).indexOf(route);

  // Installation before credit: force this fist breadcrumb to be active if the sales journey is scheduleBeforeCredit
  if (salesJourney === 'scheduleBeforeCredit' && route === '/installation') currentIndex = 0;

  // Installation before credit: force the second breadcrumb to be active if
  // the sales journey is scheduleBeforeCredit and the route is /contact-credit
  if (salesJourney === 'scheduleBeforeCredit' && route === '/contact-credit') currentIndex = 1;

  if (totalBreadcrumbs === 0 || currentIndex < 0) return null;

  return (
    <ProgressStyled totalBreadcrumbs={totalBreadcrumbs} step={currentIndex + 1}>
      {breadcrumbs.map(({ name }, index) => (
        <div
          key={name}
          className={`${currentIndex === index ? 'is-current' : ''} ${index < currentIndex ? 'is-complete' : ''}`}
        >
          <span className="step-number">{index + 1}</span>
          <Check className="step-check" />
          <span className="step-name">{name}</span>
        </div>
      ))}
    </ProgressStyled>
  );
};

Progress.displayName = 'Progress';

export default Progress;
