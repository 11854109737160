import React, { FC, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '~/components/Button';
import { CloseThin } from '~/components/Icons/fill';
import { SessionModalStyled } from './SessionModalStyled';
import { FuelContext } from '~/providers/FuelProvider';
import { setSessionExpired, resetState, removeQuote } from '~/redux/actions/common';
import { ReduxState } from '~/types/redux';
import Modal from '~/components/Modal';
import { trackEvent } from '~/helpers/tracking';
import Cookies from 'js-cookie';
import { setNewRelicCustomAttribute } from '~/helpers/newrelic';

const SessionModal: FC = () => {
  const sessionExpired = useSelector((state: ReduxState) => state.sessionExpired);
  const { router } = useContext(FuelContext);
  const dispatch = useDispatch();
  const currentPage = router.pathname;

  if (!sessionExpired) return null;

  trackEvent({
    action: 'elementViewed',
    data: {
      elementType: 'Modal',
      location: currentPage,
      position: 'Session Expired Modal',
    },
  });
  setNewRelicCustomAttribute([{ name: 'sessionExpiredModalViewed', value: 'true' }]);

  const handleClose = () => {
    trackEvent({
      action: 'elementClicked',
      data: { 
        elementType: 'Modal Close',
        text: 'X',
        location: currentPage,
        position: 'Session Expired Modal',
      }
    });
    dispatch(setSessionExpired(false));
  };

  const handleStartNewSession = async () => {
    trackEvent({
      action: 'elementClicked',
      data: { 
        elementType: 'Modal CTA',
        text: 'Start New Session',
        location: currentPage,
        position: 'Session Expired Modal',
      }
    });
    setNewRelicCustomAttribute([{ name: 'sessionExpiredModalAccepted', value: 'true' }]);

    // Reset the user's session
    Cookies.remove('orderSessionStarted');
    Cookies.remove('clientSideTrackingIds');
    dispatch(removeQuote());
    window.sessionStorage.removeItem('persist:fuel');
    dispatch(resetState());

    // Send to start of cart flow
    window.location.href = `${process.env.basePath}/`;
  };

  return (
    <Modal visible zIndex={20000}>
      <SessionModalStyled>
        <CloseThin onClick={() => handleClose()} data-testid="modal-btn-close" />
        <div className="session__modal">
          <header className="session__header">
            <p className="session__title">Session expired</p>
          </header>
          <div className="session__subtitle">Your current session has expired due to inactivity. Please begin a new one to continue your order.</div>
          <Button className="session__button" onClick={() => handleStartNewSession()} data-testid="session-modal-btn">
            Start New Session
          </Button>
        </div>
      </SessionModalStyled>
    </Modal>
  );
};

SessionModal.displayName = 'SessionModal';

export default SessionModal;


