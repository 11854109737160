/* eslint max-len: 0 */
import React, { SVGProps } from 'react';

const ShieldWifi = (props: SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      width="54"
      height="61"
      viewBox="0 0 54 61"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="icon icon--shieldWifi"
      {...props}
    >
      <path fillRule="evenodd" clipRule="evenodd" d="M30.732 9.06669C36.0654 12.2667 42.1987 13.7334 48.332 13.2V29.7334C48.332 37.2 44.332 44.1334 37.932 48L26.9987 54.5334L16.0654 48C9.53203 44.1334 5.66536 37.2 5.66536 29.7334V13.3334C11.7987 13.7334 17.932 12.4 23.2654 9.20002L26.9987 6.93335L30.732 9.06669ZM48.1987 7.86669L53.6654 7.33335V29.6C53.6654 38.9334 48.732 47.6 40.732 52.4L26.9987 60.6667L13.2654 52.4C5.26536 47.6 0.332031 38.9334 0.332031 29.6V7.33335L5.66536 7.86669C10.8654 8.40002 16.0654 7.20002 20.4654 4.53335L26.9987 0.666687L33.3987 4.53335C37.932 7.20002 42.9987 8.40002 48.1987 7.86669ZM28.3321 20.6667C32.9988 20.6667 37.3988 22.2667 40.8654 24.8L44.0654 20.5334C39.6654 17.2 34.1988 15.3334 28.3321 15.3334H25.6654C19.7988 15.3334 14.4654 17.2 10.0654 20.4L13.2654 24.6667C16.7321 22.1334 20.9988 20.6667 25.6654 20.6667H28.3321ZM37.6655 29.0667C34.9988 27.2 31.7988 26 28.3322 26H25.6655C22.1988 26 18.9988 27.0667 16.4655 28.9334L19.6655 33.2C21.3988 32 23.5322 31.3334 25.7988 31.3334H28.4655C30.7322 31.3334 32.8655 32 34.5988 33.3334L37.6655 29.0667ZM26.8652 36.6667C23.9319 36.6667 21.5319 39.0667 21.5319 42C21.5319 44.9334 23.9319 47.3334 26.8652 47.3334C29.7986 47.3334 32.1986 44.9334 32.1986 42C32.1986 39.0667 29.7986 36.6667 26.8652 36.6667Z" fill={props?.fill || "#FF0037"}/>
    </svg>
  );
};

ShieldWifi.displayName = 'ShieldWifi';

export default ShieldWifi;
